/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import {
  toggleCouponField,
  validateCoupon,
  setStripe,
} from '../client/subscribe/actions'
import TierModal from './TierModal'
import { CPaymentIcon, LaunchpassGray, CreditCardIcon } from './Icons'
import { isNonZeroValue, getNumber, formatPrice, getJoinButtonText, getDiscountedAmount } from '../utils'
import { getFormattedPrices } from '../utils'
export const makePeriodSwitchHandler = (period) => (event) => {
  event.preventDefault()
  store.dispatch({ type: period })

  return false
}



// eslint-disable-next-line react/function-component-definition
const PaymentIcons = ({ coinbaseEnabled }) => (
  <>
    <CreditCardIcon />
    {coinbaseEnabled && <CPaymentIcon />}
  </>
)

export const getPriceString = (period, formattedPrice) => {
  switch (period) {
    case 'monthly':
      return `${formattedPrice}/month`
    case 'yearly':
      return `${formattedPrice}/year`
    case 'semiannual':
      return `${formattedPrice}/semiannually`
    case 'quarterly':
      return `${formattedPrice}/quarterly`
    case 'weekly':
      return `${formattedPrice}/weekly`
    case 'daily':
      return `${formattedPrice}/day`
    case 'biweekly':
      return `${formattedPrice}/biweekly`
    case 'one-time':
      return `${formattedPrice}/one time`
    default:
      return ''
  }
}

export const isElementVisible = (element) => {
  if (!element) {
    return false
  }

  const rect = element.getBoundingClientRect()

  const windowHeight = window.innerHeight || document.documentElement.clientHeight
  const windowWidth = window.innerWidth || document.documentElement.clientWidth

  // Check if the element is in the viewport.
  const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0
  const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0

  return vertInView && horInView
}

export function StickyButton({
  styles,
  className,
  buttonColor,
  text = 'Join Now',
}) {
  const [isVisible, setIsVisible] = React.useState(false)

  function handleClick() {
    const widget = document.querySelector('.widget')
    widget.scrollIntoView({ behavior: 'smooth' })
  }

  React.useEffect(() => {
    const widget = document.querySelector('.widget')

    function handleScroll() {
      if (isElementVisible(widget)) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    window.addEventListener('touchstart', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('touchstart', handleScroll)
    }
  }, [])

  // Hide mobile join section when widget displayed & vice-versa
  React.useEffect(() => {
    const widget = document.querySelector('.widget')
    const mobileJoin = document.querySelector('.mobileJoin')

    console.log(isElementVisible(mobileJoin))

    if (isElementVisible(widget)) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [isVisible, setIsVisible])

  return (
    <div
      className={classNames(
        styles.mobileJoinWrapper,
        isVisible ? styles.showOnMobile : '',
        'mobileJoin',
      )}
    >
      <button
        className={className}
        style={{
          background: buttonColor || '#3B5B4F',
        }}
        onClick={handleClick}
      >
        {text}
      </button>
    </div>
  )
}


const PlanSelectButton = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid ${(p) => (p.selected ? p.borderColor : '#e3e7eb')};
  box-shadow: ${(p) => p.selected && '0px 4px 7px rgba(0, 0, 0, 0.15)'}
  border-radius: 8px;
  margin-top: 24px;
  cursor: ${(p) => (p.alternatePayment ? 'pointer' : 'default')};
  padding: 12px 16px;
  justify-content: space-between;

  &:hover {
    border: 1px solid ${(p) => p.borderColor || '#e3e7eb'};
    background: ${(p) => p.alternatePayment && '#f7f8fa'};
  }

  &:focus {
    border: 1px solid ${(p) => p.borderColor || '#e3e7eb'};
  }
`

function StripeFormElementsV2({
  preview,
  styles,
  url,
  price,
  yearlySelected,
  currency = 'usd',
  errors,
  period,
  trialPeriod,
  openModal,
  modalStyles,
  channel,
  image,
  group,
  subscribing,
  couponValidating,
  buttonColor,
  textColor,
  backgroundColor,
  status,
  alternatePayment,
  alternatePaymentAmount,
  joiningPayment,
  joiningPaymentAmount,
  waiveJoiningFeeForYearlyOption,
  convertCode,
  couponsEnabled,
  requireNameEnabled,
  requireAddressEnabled,
  customSetting1Enabled,
  customSetting2Enabled,
  customSetting3Enabled,
  verifyEmailEnabled,
  useOauthEnabled,
  coupon,
  couponFieldVisible,
  couponValid,
  couponId,
  couponAmountOff,
  couponPercentOff,
  couponDurationInMonths,
  couponDuration,
  couponSubmitted,
  couponData,
  customTOS,
  displayName,
  couponCurrency = 'usd',
  email,
  stripeKey,
  stripeKeySCA,
  stripeConnectId,
  stripe,
  offerName,
  platform,
  coinbaseEnabled,
  coinbaseCharge,
  isEmbed,
  domain,
  mediaAssets,
  displayMediaEmbed,
}) {
  const [tierModalOpen, setTierModalOpen] = React.useState(isEmbed || false)
  React.useEffect(() => {
    if (window.stripe) {
      store.dispatch(setStripe(window.stripe))
    } else {
      // set to null for SSR
      store.dispatch(setStripe(null))
    }

    if (coupon) {
      store.dispatch(toggleCouponField(true))
      store.dispatch(validateCoupon(coupon))
    }
  }, [])
  
    console.log('V2 domain', domain, group, period, price)
    const formattedPrices = getFormattedPrices({ customSetting1Enabled, domain, group, period, price, alternatePayment, alternatePaymentAmount, joiningPayment, joiningPaymentAmount, currency}, couponData, yearlySelected)
    
  const inputStyles = status === 'Disabled' ? styles.disabledFormInput : styles.formInput

  if (couponCurrency === null || couponCurrency === undefined) {
    couponCurrency = 'usd'
  }
  if (currency === null || currency === undefined) {
    currency = 'usd'
  }

  trialPeriod = parseInt(trialPeriod, 10)

  const currencyUC = formattedPrices?.currency?.toUpperCase() || 'USD'


  
  
  const parsedTrialPeriod = parseInt(trialPeriod, 10)

  const joinButtonText = getJoinButtonText(trialPeriod)
  // console.log('yearly discount savings',formattedPrices.yearlyDiscountSavings, getNumber(formattedPrices.yearlyDiscountSavings))
  const displaySavedPrice = alternatePayment && Number(formattedPrices.yearlyDiscountSavingsAmount) > 0
  
  const monthlyButton = ({ formattedPrices, value, label }) => (
    <PlanSelectButton
      className={classNames(styles.planSelectButton)}
      borderColor={buttonColor}
      selected={!yearlySelected}
      alternatePayment={alternatePayment}
      onClick={makePeriodSwitchHandler('SELECT_MONTHLY')}
    >
      <div className={styles.planTitle}>
        <div className={styles.planText} style={{ fontSize: '14px' }}>{(formattedPrices.period === 'yearly' && !alternatePayment) ? 'Yearly' : 'Monthly'}</div>
      </div>
      <div className={styles.planInfo}>
        <div className={styles.planPrice}>
          {label || `${formattedPrices.monthlyPriceAfterCoupon}/month`}
        </div>
        <PaymentIcons coinbaseEnabled={coinbaseEnabled} />
      </div>
    </PlanSelectButton>
  )

  const yearlyButton = ({ formattedPrices }) => (
    <PlanSelectButton
      className={classNames(styles.planSelectButton)}
      borderColor={buttonColor}
      selected={yearlySelected}
      alternatePayment={alternatePayment}
      onClick={makePeriodSwitchHandler('SELECT_YEARLY')}
    >
      <div className={styles.planTitle}>
        
        <div className={styles.planText} style={{ fontSize: '14px' }}>{(formattedPrices.period === 'yearly' && !alternatePayment) ? 'Monthly' : 'Yearly'}</div>
        {displaySavedPrice && (
          <div className={styles.planDiscount}>
            {`Save ${formattedPrices.yearlyDiscountSavings}`}
          </div>
        )}
      </div>
      <div className={styles.planInfo}>
        <div className={styles.planPrice}>
          {`${formattedPrices.yearlyPriceAfterCoupon}/year`}
        </div>
        <PaymentIcons coinbaseEnabled={coinbaseEnabled} />
      </div>
    </PlanSelectButton>
  )

  return (
    <>
      {tierModalOpen && (
        <TierModal
          preview={preview}
          isEmbed={isEmbed}
          group={group}
          email={email}
          currency={currency}
          errors={errors}
          channel={channel}
          status={status}
          buttonColor={buttonColor}
          textColor={textColor}
          backgroundColor={backgroundColor}
          modalOpen={tierModalOpen}
          setModalOpen={setTierModalOpen}
          styles={modalStyles}
          url={url}
          price={price}
          yearlySelected={yearlySelected}
          period={period}
          openModal={openModal}
          alternatePayment={alternatePayment}
          joiningPayment={joiningPayment}
          joiningPaymentAmount={joiningPaymentAmount}
          waiveJoiningFeeForYearlyOption={waiveJoiningFeeForYearlyOption}
          convertCode={convertCode}
          couponsEnabled={couponsEnabled}
          image={image}
          displayName={displayName}
          verifyEmailEnabled={verifyEmailEnabled}
          useOauthEnabled={useOauthEnabled}
          requireNameEnabled={requireNameEnabled}
          requireAddressEnabled={requireAddressEnabled}
          customSetting1Enabled={customSetting1Enabled}
          customSetting2Enabled={customSetting2Enabled}
          customSetting3Enabled={customSetting3Enabled}
          stripeKey={stripeKey}
          stripeKeySCA={stripeKeySCA}
          stripeConnectId={stripeConnectId}
          customTOS={customTOS}
          offerName={offerName}
          platform={platform}
          couponFieldVisible={couponFieldVisible}
          couponValid={couponValid}
          couponId={couponId}
          couponAmountOff={couponAmountOff}
          couponPercentOff={couponPercentOff}
          couponDurationInMonths={couponDurationInMonths}
          couponDuration={couponDuration}
          couponSubmitted={couponSubmitted}
          couponValidating={couponValidating}
          subscribing={subscribing}
          stripe={stripe}
          inputStyles={inputStyles}
          coinbaseEnabled={coinbaseEnabled}
          coinbaseCharge={coinbaseCharge}
          modalStyles={modalStyles}
          
          parsedTrialPeriod={parsedTrialPeriod}
          displaySavedPrice={displaySavedPrice}

          formattedPrices={formattedPrices}
          mediaAssets={mediaAssets}
          displayMediaEmbed={displayMediaEmbed}
        />
      )}

      {!isEmbed && (
        <>
          <div className={classNames(styles.paperWrapper, 'widget')}>
            <h2 className={styles.getAccess} style={{ color: textColor }}>
              Get access today!
            </h2>
            <div
              className={styles.paper}
              style={
                status === 'Disabled' || preview
                  ? { cursor: 'not-allowed', pointerEvents: 'none' }
                  : {}
              }
            >
              <header className={styles.header}> Join {displayName}
              </header>

              {parsedTrialPeriod > 0 && (
                <div className={styles.trialDetail}>
                  {`${parsedTrialPeriod} day free trial`}
                </div>
              )}
              
              {customSetting1Enabled === false &&
              <>
                { monthlyButton({ formattedPrices,  value: "Membership",  label: `${formattedPrices.currentPrice} ${formattedPrices.autoRenewText}` })}
              </>
              }
              {customSetting1Enabled !== false && period === 'monthly' || period === 'yearly' ? (
                alternatePayment ? (
                  <>
                   { monthlyButton({formattedPrices}) }
                   { yearlyButton({formattedPrices}) }
                  </>
                ) : (
                  <>
                    {customSetting1Enabled !== false && period === 'monthly' && monthlyButton({formattedPrices}) }
                    {customSetting1Enabled !== false && period === 'yearly' &&  yearlyButton({formattedPrices}) }
                  </>
                )
              ) : (
                <>
                  { customSetting1Enabled !== false &&
              
                <div
                  className={classNames(styles.planSelectButton, styles.planSelectButtonSingle)}
                  style={{ border: `1px solid ${buttonColor}` }}
                >
                  <>
                    <div className={styles.planTitle}>
                    <div className={styles.planText}>
                      {period} Plan
                    </div>
                  </div>
                  <div className={styles.planInfo}>
                    <div className={styles.planPrice}>{formattedPrices.currentPrice}</div>
                    <PaymentIcons coinbaseEnabled={coinbaseEnabled} />
                  </div>
                  </>
                  
                  
                </div>
                }
                </>
                
              )}
              
              <button
                className={styles.joinButton}
                style={{ background: buttonColor || '#3B5B4F' }}
                onClick={() => setTierModalOpen(true)}
              >
                {joinButtonText}
              </button>

              <hr className={styles.separator} />

              <div className={styles.launchpass}>
                Powered by LaunchPass <LaunchpassGray />
              </div>
            </div>
          </div>
          {!preview && (
            <StickyButton
              styles={styles}
              buttonColor={buttonColor}
              className={styles.mobileJoinButton}
              text={joinButtonText}
            />
          )}
        </>
      )}
    </>
  )
}

export default StripeFormElementsV2