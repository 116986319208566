import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

function ScrollButton({ direction, onClick }) {
  return (
    <button
      onClick={onClick}
      className="w-8 h-8 hover:bg-gray-400 flex items-center justify-center bg-brutal-black text-brutal-white rounded-full hover:scale-110 transition-transform"
    >
      {direction === 'left' ? (
        <ChevronLeftIcon className="h-5 w-5" />
      ) : (
        <ChevronRightIcon className="h-5 w-5" />
      )}
    </button>
  )
}

export default ScrollButton;
