import * as React from "react";
const SvgFacebookSecondary = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#facebook-secondary_svg__a)">
      <path
        fill="#747D85"
        d="M20 10.209c0-5.523-4.477-10-10-10s-10 4.477-10 10c0 4.69 3.229 8.625 7.584 9.706v-6.65H5.522v-3.056h2.062V8.892c0-3.403 1.54-4.981 4.882-4.981.634 0 1.727.124 2.174.248v2.77a13 13 0 0 0-1.155-.037c-1.64 0-2.273.621-2.273 2.236v1.081h3.266l-.56 3.056h-2.706v6.87C16.162 19.539 20 15.323 20 10.21"
      />
    </g>
    <defs>
      <clipPath id="facebook-secondary_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFacebookSecondary;
