import * as React from "react";
const SvgTwitchSecondary = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <g clipPath="url(#twitch-secondary_svg__a)">
      <path
        fill="#fff"
        d="m14.41 9.402-2.143 2.143h-2.142L8.25 13.419v-1.875H5.839V3.51h8.571z"
      />
      <path
        fill="#545C63"
        d="m5.021 1.872-2.88 2.88v10.37h3.456v2.88l2.88-2.88h2.305l5.185-5.184V1.871zm9.794 7.49-2.304 2.304h-2.305L8.19 13.682v-2.016H5.597V3.024h9.218z"
      />
      <path
        fill="#545C63"
        d="M12.804 5.384h-1.072v3.214h1.072zM9.858 5.384H8.786v3.214h1.072z"
      />
    </g>
    <defs>
      <clipPath id="twitch-secondary_svg__a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTwitchSecondary;
