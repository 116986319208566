import React from 'react';
import PropTypes from 'prop-types';

function Switch({ onChange, defaultChecked, checked }) {
  return (
    <label
      htmlFor="toggleTwo"
      className="switch flex items-center cursor-pointer select-none text-dark dark:text-white"
    >
      <div className="relative">
        <input
          type="checkbox"
          id="toggleTwo"
          checked={checked}          
          className="peer sr-only"
          onChange={onChange}
        />
        <div
          className="block h-8 rounded-full dark:bg-dark-2 bg-gray-300 w-14 peer-checked:bg-primary"
        />
        <div
          className="absolute w-6 h-6 transition bg-white rounded-full dot left-1 top-1 peer-checked:translate-x-full"
        />
      </div>
    </label>
  )
}

Switch.propTypes = {
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
};

Switch.defaultValues = {
  defaultChecked: false,
  checked: false,
};

export default Switch;
