/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { updateFormField } from '../client/subscribe/actions';

function DefaultTOS({
  textColor, isV3, isV4, styles,
}) {
  // Automatically accept TOS when enabled and no custom TOS URL is set
  useEffect(() => {
    store.dispatch(updateFormField('tosAccepted', true));
  }, []);

  const containerClass = isV4 ? 'text-left ml-0' : '';
  const labelClass = `${isV3 ? styles.tosLabel : ''} !text-xs ml-0 font-normal text-gray-100`;
  const labelStyle = !isV3 ? { color: textColor, fontSize: 12 } : undefined;
  const spanClass = !isV3 && !isV4 ? 'text-white text-sm' : '!text-xs font-normal text-gray-100';
  const linkClass = `${isV3 ? styles.tosLink : ''} ${!isV3 && !isV4 ? 'text-sm' : 'text-xs'} font-normal`;
  const linkStyle = !isV3 && !isV4 ? { color: textColor } : undefined;

  return (
    <div className={containerClass}>
      <label className={labelClass} style={labelStyle} htmlFor="tos">
        <span className={spanClass} style={{ display: 'inline-block' }}>
          By completing signup, I agree to the
          {' '}
          <a
            href="https://www.launchpass.com/terms"
            className={linkClass}
            style={linkStyle}
            target="_blank"
            rel="noreferrer"
          >
            terms & conditions
          </a>
          {' '}
          of LaunchPass.
        </span>
      </label>
    </div>
  );
}

export default DefaultTOS;
