import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import HeaderSection from './components/HeaderSection';
import OfferCard from './components/OfferCard';
import OfferCardList from './components/OfferCardList';
import CarouselGallery from '../../components/CarouselGallery';
import { LaunchpassBlue } from '../../components/Icons';
import Input from '../../components/v2/Input';
import FeaturesList from './components/FeaturesList';
import StripeFormElementsV4 from '../../components/StripeFormElementsV4'
import Spinner from '../../clientAdmin/components/Spinner';
import Welcome from '../../components/WelcomeModalV2'
import Testimonials from './components/Testimonials'
import {
  validateAndPostEmail,
  openModal,
  setPage,
  setSelector,
  updateFormField,
  updateCouponConfig,
  setOffer,
} from '../../client/subscribe/actions';
import {
  SupportedPaymentsIcon, CoinbaseIcon, GoogleIcon, AppleIcon,
} from '../../icons';
import Button from '../../components/v2/Button';
import useScrollPastMidpoint from '../../hooks/use-scroll-past-midpoint';
import Socials from './components/Socials';
import { formatPrice } from '../../utils';
import TermsAndConditions from './components/TermsAndConditions'

function ExpressPaymentOptions({ onClick, expressCheckoutOptions }) {
  const availablePaymentMethods = expressCheckoutOptions.availablePaymentMethods;
  return (
    <>
      {availablePaymentMethods?.applePay && (
        <Button
          variant="default"
          className="!bg-black !hover:opacity-50 w-full"
          onClick={() => onClick('applePay')}
          icon={<AppleIcon width="18px" height="18px" />}
        >
          Pay
        </Button>
      )}
      {availablePaymentMethods?.googlePay && (
        <Button
          variant="default"
          className="!bg-black !hover:opacity-50 w-full"
          onClick={() => onClick('google')}
          icon={<GoogleIcon width="18px" height="18px" />}
        >
          Pay
        </Button>
      )}
      {/* {(!availablePaymentMethods?.googlePay && !availablePaymentMethods?.applePay) && availablePaymentMethods?.link && (
        <Button
          variant="default"
          className="!bg-black !hover:opacity-50 w-full"
          onClick={() => onClick('link')}
        >
          Pay link
        </Button>
      )} */}
    </>
  );
}

function BusinessPage({
  data, isPreview = false, stripe, expressCheckoutOptions, globalState, modalStyles,
}) {
  if (typeof window !== 'undefined' && window.location.search.indexOf('redirect_status=succeeded') !== -1) {
    window.location.href = '/portal';
  }
  // console.log('data', data)
  const observedRef = useRef(null);
  const containerRef = useRef(null);
  const hasScrolledPastMidpoint = useScrollPastMidpoint(observedRef, containerRef, 75);
  const offerRefs = useRef({});

  const {
    businessName,
    shortBio,
    logo,
    platform,
    businessCategory,
    offers = [],
    tagline,
    mediaAssets,
    displayMediaGallery = false,
    features = [],
    testimonials = [],
    socials = {},
    status,
    domain,
  } = data;

  const formError = globalState && globalState.form && globalState.form.errors && globalState.form.errors.length > 0 ? globalState.form.errors[0] : null

  const enabledOffers = (offers ?? []).filter((offer) => offer.enabled);

  const [selectedOfferId, setSelectedOfferId] = useState(enabledOffers?.length ? enabledOffers[0].id : null);
  const [formErrors, setFormErrors] = useState({});

  const offer = (offers ?? []).find((offerItem) => offerItem.id === selectedOfferId);

  let finalFeatures = features;
  if (offer?.features?.length) {
    finalFeatures = offer.features;
  }

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  // This effect will set email error with errors coming from API
  // we do not see for other fields cause all other fields are in payment modal
  // and error will be displayed there, only email field for free members will visible
  // out of payment modal on Business page, so error must be surfaced.
  useEffect(() => {
    if (formError) {
      setError('email', { message: formError.message })
    }
  }, [formError])

  const setOfferPaymentData = (page) => {
    store.dispatch(updateCouponConfig({
      couponsEnabled: page.couponsEnabled,
    }))

    store.dispatch(setPage({
      ...page,
      image: page.image,
      pageId: page.id,
    }))
    const {
      alternatePayment,
      alternatePaymentAmount,
      joiningPayment,
      joiningPaymentAmount,
      waiveJoiningFeeForYearlyOption,
      currency,
      price,
      domain,
      period,
      priceYearly,
    } = page;
    store.dispatch(setSelector({
      alternatePayment,
      alternatePaymentAmount,
      joiningPayment,
      joiningPaymentAmount,
      waiveJoiningFeeForYearlyOption,
      currency,
      price,
      domain,
      period,
      priceYearly,
      yearlySelected: false,
      currentPrice: period === 'yearly'
        ? alternatePayment
          ? alternatePaymentAmount
          : price
        : price,
    }))
  }

  useEffect(() => {
    if (offers?.length) {
      const firstEnabledOffer = offers.find((offer) => offer.enabled);
      if (firstEnabledOffer) {
        setSelectedOfferId(firstEnabledOffer.id);

        setTimeout(() => {
          handleOfferSelect(firstEnabledOffer.id);
        }, 250)

        if (firstEnabledOffer.period !== 'free') {
          setOfferPaymentData(firstEnabledOffer);
        }
      }
    }
  }, [offers]);

  const handleOfferSelect = (id) => {
    store.dispatch(updateFormField('tosAccepted', true));
    setSelectedOfferId(id);
    setFormErrors({});
    setValue('email', '');
    clearErrors('email');

    const page = offers?.find((item) => item.id === id);
    store.dispatch(setOffer(page));
    if (page.period !== 'free') {
      setOfferPaymentData(page);
    }
  };

  const joinAsFreeMember = (data) => {
    if (isPreview) return;

    if (typeof clicky !== 'undefined') {
      clicky.goal('freeInviteSignup');
    }

    if (window?.lp_event) lp_event('freeInviteSignup');

    if (data.email) {
      store.dispatch(validateAndPostEmail({ email: data.email }, offer));
      setValue('email', '');
    }
  };

  const onQuickPay = (paymentType) => {
    if (isPreview || !data.stripeConnectId) return;

    const paymentEmbedEl = document.getElementById('payment-embed');
    if (paymentEmbedEl) {
      store.dispatch(setSelector({
        quickPaymentType: paymentType,
        paymentModalOpen: true,
      }))
      paymentEmbedEl.style.display = 'block';
    }
  };

  const onJoinNow = () => {
    if (isPreview || !data.stripeConnectId) return;

    const page = offers.find((item) => item.id === selectedOfferId);
    if (page.period === 'free') return;

    const paymentEmbedEl = document.getElementById('payment-embed');
    if (paymentEmbedEl) {
      store.dispatch(setSelector({
        quickPaymentType: null,
        paymentModalOpen: true,
      }))
      paymentEmbedEl.style.display = 'block';
    }
  };

  useEffect(() => {
    if (selectedOfferId && offerRefs.current[selectedOfferId]) {
      const element = offerRefs.current[selectedOfferId];
      const container = document.querySelector('.scrollable-container'); // Adjust selector if necessary

      if (container) {
        const containerRect = container.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();

        // Ensure element is visible in the container's horizontal scroll
        if (elementRect.left < containerRect.left || elementRect.right > containerRect.right) {
          const scrollOffset = elementRect.left - containerRect.left;
          container.scrollBy({
            left: scrollOffset,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [selectedOfferId]);

  const assignOfferRef = (id, node) => {
    if (node) {
      offerRefs.current[id] = node;
    }
  };

  let galleryAssets = [];
  let showMediaGallery = false;
  if (offer) {
    showMediaGallery = offer.displayMediaEmbed || displayMediaGallery;
    if (offer.displayMediaEmbed) {
      galleryAssets = offer.mediaAssets && offer.mediaAssets.length
        ? offer.mediaAssets
        : mediaAssets
    } else {
      galleryAssets = mediaAssets;
    }
  }

  const handleClickInPreview = useCallback((e) => {
    if (isPreview) {
      e.preventDefault()
      e.stopPropagation()
      store.dispatch(openModal('previewModal'))
    }
    return false;
  }, [isPreview]);

  
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(Boolean(window.innerWidth < 768));
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="flex flex-col h-full mx-auto mt-0 w-full flex-1 relative overflow-y-hidden"
    >
      <div className="flex-1 flex flex-col overflow-auto justify-between" ref={containerRef}>
        {/* Header */}
        <div
          className="border-b border-gray-400 px-4"
        >
          <div className="max-w-[1200px] w-full flex flex-col mx-auto mt-0">
            <HeaderSection
              businessName={businessName}
              tagline={tagline}
              logo={logo}
              platform={platform}
              businessCategory={businessCategory}
            />
          </div>
        </div>
        <div
          id="offer-card-list-container"
          className={`border-b bg-white border-gray-400  fixed z-10 top-0 left-0 w-screen 
            ${hasScrolledPastMidpoint && !isMobile
            ? 'animate-slide-in block'
            : 'hidden'}`}          style={{ zIndex: 999 }}
        >
          {(offers ?? []).filter((offer) => offer.enabled).length > 1 && (
          <div className="border-t border-gray-400 flex flex-col items-center justify-center">
            <div className="w-full flex flew-row border-b justify-center border-gray-400">
              <div className="w-full flex flew-row max-w-[1200px] px-4 bg-white justify-center">
                <HeaderSection
                  businessName={businessName}
                  tagline={tagline}
                  logo={logo}
                  platform={platform}
                  businessCategory={businessCategory}
                  small
                />
              </div>
            </div>
            <div className="max-w-[1200px] hidescroll flex flex-col px-4 bg-white justify-center overflow-auto w-full px-4">
              <div className="flex min-w-[300px] gap-4 relative scroll-smooth snap-x snap-mandatory hide-scrollbar cursor-grab active:cursor-grabbing">
                {enabledOffers.map((offer) => (
                  <OfferCard
                    ref={(el) => assignOfferRef(offer.id, el)}
                    key={offer.id}
                    offer={offer}
                    className="md:max-w-[240px] md:min-w-[250px] max-w-[150px] min-w-[150px] snap-start cursor-pointer"
                    selected={selectedOfferId === offer.id}
                    onSelect={handleOfferSelect}
                    tabbed
                  />
                ))}
              </div>
            </div>
          </div>
          )}
        </div>

        {/* Content */}
        <div
          className="w-full flex-1 flex flex-col mt-0 mb-8"
        >
          <div className="max-w-[1200px] w-full mx-auto flex flex-col">
            {/* Offers section */}
            <div className="gap-[40px] p-4 mt-2 sm:flex sm:flex-col md:flex-row">
              <div className="flex flex-col md:w-8/12 w-full">
                <div className="flex flex-col bg-white z-10 top-0 md:relative" ref={observedRef}>
                  <div className="flex flex-col gap-4">
                    {enabledOffers.length > 1 && (
                    <>
                      {enabledOffers.length > 0 && (
                        <OfferCardList
                          selectedOfferId={selectedOfferId}
                          offers={enabledOffers}
                          onSelect={handleOfferSelect}
                        />
                      )}
                    </>
                    )}

                    <div className="text-xl md:text-3xl overflow-hidden font-semibold text-black-pearl mt-4">
                      {(offer?.offerName || offer?.displayName)}
                    </div>
                  </div>
                </div>
                <div
                  className={`text-md md:text-lg text-fiord-500 mt-1 ${showMediaGallery ? 'pt-4' : ''}`}
                  style={showMediaGallery ? {} : {}}
                  id="media-embed"
                >
                  {/* Show this only in preview */}
                  {showMediaGallery && (
                    <CarouselGallery
                      assets={galleryAssets}
                      options={{ align: 'start', loop: true }}
                    />
                  )}
                </div>
                <div
                  className="text-md md:text-lg text-fiord-500 mt-1"
                  dangerouslySetInnerHTML={{ __html: offer?.aboutHTML }}
                />

                <div className="bg-gray-400 h-[1px] w-full my-6" />
                {/* benefits */}
                {finalFeatures?.length > 0 && (
                  <div className="flex flex-col gap-4">
                    <div className="text-base font-semibold text-black-pearl">
                      Benefits
                    </div>
                    <FeaturesList features={finalFeatures} />
                  </div>
                )}

                {/* {testimonials?.length > 0 && (
                  <div className="flex flex-col gap-4">
                    <Testimonials testimonials={testimonials} />
                  </div>
                )} */}

              </div>
              <div className="relative md:w-4/12 w-full mt-4 md:mt-0">
                <div className={`flex flex-col w-full sticky 'top-[20px]'}`}>
                  <div className="rounded-2xl flex-col gap-3 hidden md:flex border border-gray-400 p-6 shadow-card z-10 bg-white">
                    <div className="flex flex-col">
                      <h5 className="text-xl m-0 font-semibold text-black-pearl overflow-hidden">
                        {offer?.offerName || offer?.displayName}
                      </h5>
                      <div className="text-sm text-black-pearl font-medium overflow-hidden text-ellipsis">
                        {offer && (offer?.period === 'free' ? 'Free' : `${formatPrice((offer?.price / 100), offer?.currency)}/${offer?.period}`)}
                      </div>
                    </div>

                    {offer?.status === 'Active' && offer?.period !== 'free' && (
                    <>
                      <ExpressPaymentOptions onClick={onQuickPay} expressCheckoutOptions={expressCheckoutOptions ?? {}} />
                      <Button
                        variant="default"
                        className={`lp${selectedOfferId} lpbtn w-full`}
                        onClick={onJoinNow}

                      >
                        Join Now
                      </Button>
                    </>
                    )}

                    {offer?.status === 'Hidden' && (
                      <div className="text-sm my-2 text-gray-200 w-full flex items-center">
                        As we work to accommodate our growing community, signups are temporarily disabled.
                      </div>
                    )}

                    {offer?.status !== 'Hidden' && offer?.period === 'free' && (
                      <form onSubmit={handleSubmit(joinAsFreeMember)}>
                        <div className="flex flex-col gap-5">
                          <Controller
                            name="email"
                            control={control}
                            type="email"
                            rules={{
                              required: 'This field is required',
                            }}
                            render={({ field: { onChange, value, name } }) => (
                              <Input
                                type="email"
                                label="Email"
                                id="email"
                                name="email"
                                value={value}
                                onChange={onChange}
                                placeholder="Enter email address"
                                error={errors.email}
                              />
                            )}
                          />
                          {offer && offer.status !== 'Hidden' && (
                            <div className="flex flex-col gap-2">
                              <TermsAndConditions
                                formErrors={formErrors}
                                customTOS={offer?.customTOS}
                              />
                              <Button
                                variant="default"
                                className="w-full"
                                type="submit"
                                disabled={isPreview || globalState?.form?.subscribing}
                              >
                                {globalState?.form?.subscribing && <Spinner color="#FFFFFF" size={25} />}
                                {!globalState?.form?.subscribing && 'Join Now'}
                              </Button>
                            </div>
                          )}
                        </div>
                      </form>
                    )}

                    {offer?.period !== 'free' && offer?.status === 'Active' &&(
                      <div className="text-sm mt-2 text-gray-200 w-full inline text-center">
                        <span className="text-xs text-gray-100 align-bottom">
                          Secured payment with
                        </span>
                        <span className="inline-block">
                          <SupportedPaymentsIcon style={{ width: '114px' }} />
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="md:rounded-2xl my-5 border-t md:border border-gray-400 pt-4 p-0 md:p-6 flex flex-col gap-1">
                    <div className="text-black-pearl text-md font-semibold">
                      About
                      {' '}
                      {businessName}
                    </div>
                    <div className="text-black-pearl text-md mt-3">
                      {shortBio}
                    </div>
                    <Socials socials={socials} isPreview={isPreview} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a 
          href="/"
          className="hidden no-underline border border-t border-water-500 p-4 bg-light-gray-100 justify-center items-center flex gap-1"
        >
          <span className="text-gray-200 text-sm">Powered by</span>
          <span className="text-gray-100 font-medium text-sm">LaunchPass</span>
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.135 0.5H4.86574C2.68437 0.5 0.916016 2.25193 0.916016 4.41304V14.587C0.916016 16.7481 2.68437 18.5 4.86574 18.5H15.135C17.3164 18.5 19.0847 16.7481 19.0847 14.587V4.41304C19.0847 2.25193 17.3164 0.5 15.135 0.5ZM8.34903 11.9627V7.29714C8.47004 6.19424 8.98733 5.17122 9.80647 4.41479C10.6256 5.17122 11.1429 6.19424 11.2639 7.29714V11.9627C10.8562 12.2969 10.3344 12.4631 9.80647 12.4267C9.27854 12.4631 8.75672 12.2969 8.34903 11.9627ZM7.8993 9.66897L7.9783 9.51245L7.97632 12.3651L6.76218 13.2651H6.4462V12.7067L6.4462 12.6957V12.6957C6.44618 12.6406 6.44616 12.5978 6.45093 12.5556C6.46743 12.4097 6.54116 12.2702 6.86976 11.6487C7.08503 11.2415 7.40969 10.6274 7.8993 9.66897ZM11.6361 9.51245L11.7151 9.66897C12.2046 10.6273 12.5292 11.2414 12.7444 11.6486L12.7444 11.6486C13.0729 12.2702 13.1466 12.4097 13.1631 12.5556C13.1679 12.5978 13.1679 12.6406 13.1678 12.6957L13.1678 12.7067V13.2651H12.8519L11.6361 12.3655V9.51245ZM9.86478 12.5829H9.86517C9.95483 12.5829 10.0275 12.6549 10.0275 12.7437V14.676C10.0275 14.7648 9.95483 14.8368 9.86517 14.8368H9.86478C9.77512 14.8368 9.70245 14.7648 9.70245 14.676V12.7437C9.70245 12.6549 9.77512 12.5829 9.86478 12.5829ZM10.6254 12.5829H10.625C10.5354 12.5829 10.4627 12.6549 10.4627 12.7437V14.1395C10.4627 14.2283 10.5354 14.3003 10.625 14.3003H10.6254C10.7151 14.3003 10.7878 14.2283 10.7878 14.1395V12.7437C10.7878 12.6549 10.7151 12.5829 10.6254 12.5829ZM9.10672 12.5829H9.10712C9.19677 12.5829 9.26945 12.6549 9.26945 12.7437V13.7102C9.26945 13.7991 9.19677 13.8711 9.10712 13.8711H9.10672C9.01707 13.8711 8.94439 13.7991 8.94439 13.7102V12.7437C8.94439 12.6549 9.01707 12.5829 9.10672 12.5829Z" fill="#747D85" />
          </svg>

        </a>
      </div>
      
      {/* Footer */}
      {/* <Footer /> */}
      {!isPreview && (
        <div className={`w-full flex flex-col gap-2 bg-white z-10 shadow-custom border-t border-gray-400 py-4 px-4 sticky bottom-0 left-0 w-screen transition-transform duration-300 ${isMobile ? 'flex' : 'hidden'}`}>
          
          {offer?.status === 'Active' && (
          <>
            {offer?.period === 'free' && (
            <form onSubmit={handleSubmit(joinAsFreeMember)} style={{ margin: 0 }}>
              <div className="flex flex-col gap-5">
                <Controller
                  name="email"
                  control={control}
                  type="email"
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({ field: { onChange, value, name } }) => (
                    <Input
                      type="email"
                      label="Email"
                      id="email"
                      name="email"
                      disabled={isPreview}
                      value={value}
                      onChange={onChange}
                      placeholder="Enter email address"
                      error={errors.email}
                    />
                  )}
                />
                <Button
                  variant="default"
                  className="w-full flex items-center"
                  type="submit"
                  disabled={isPreview || globalState?.form?.subscribing}
                >
                  {globalState?.form?.subscribing && <Spinner color="#FFFFFF" size={25} />}
                  {!globalState?.form?.subscribing && 'Join Now'}
                </Button>
              </div>
            </form>
            )}
            
            {offer?.period !== 'free' && offer?.status === 'Active' && (
            <>
              <ExpressPaymentOptions onClick={onQuickPay} expressCheckoutOptions={expressCheckoutOptions ?? {}} />
              
              <Button
                variant="default"
                className={`lp${selectedOfferId} lpbtn w-full`}
                onClick={onJoinNow}
              >
                Join Now
              </Button>
            </>
            )}

            {offer?.period !== 'free' && offer?.status === 'Active' && 1 == 0 && (
              <div className="text-sm mt-2 text-gray-200 w-full flex items-center justify-center hidden sm:flex">
                <span className="text-xs text-gray-100">
                  Secured payment with
                </span>
                <span>
                  <SupportedPaymentsIcon style={{ width: '114px' }} />
                </span>
              </div>
            )}
          </>
          )}
          
          {offer?.status === 'Hidden' && (
          <>
            <div className="text-sm mb-2 text-gray-200 w-full flex items-center justify-center">
              As we work to accommodate our growing community, signups are temporarily disabled.
            </div>
            <Button
              variant="default"
              disabled
            >
              Join Now
            </Button>
          </>
          )}
        </div>
      )}
      {data.status === 'Disabled' && !isPreview && (
        <div className="relative flex items-center justify-center overflow-hidden">
          <div className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
            <div className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30" style={{ clipPath: 'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)' }} />
          </div>
          <div className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
            <div className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30" style={{ clipPath: 'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)' }} />
          </div>
          <div className="flex items-center text-center gap-x-2 flex-wrap py-2 px-4 gap-y-2">
            <div className="text-sm/6 text-gray-900 m-auto text-center">
              <strong className="font-semibold mr-2">Important:</strong>
              This page currently cannot accept signups. If you are the creator of this page, please visit your
            </div>
            <div className="m-auto">
              <a href="/admin" target="_blank" className="flex-none no-underline rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
                LaunchPass Dashboard
                <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      )}

      {globalState && globalState.openModal && globalState.openModal.modalType === 'success' && (
        <Welcome styles={modalStyles} group={businessName} platform={platform} />
      )}

      {/* Add checkout form here for selected offers */}
      {globalState && !isPreview && offer?.period !== 'free' && (
        <div
          id="payment-embed"
          className="hidden fixed top-0 left-0 w-full h-full bg-black/40 z-[9997] safe-bottom"
        >
          <div className="flex justify-center items-center h-full">
            <div className="w-full h-full text-center rounded-lg fixed">
              <div className="h-full">
                <div
                  id="stripe-form"
                  className="flex w-full h-full border-none relative z-[9999]"
                >

                  <StripeFormElementsV4
                    preview={false}
                    isEmbed
                    enableBeta={globalState.enableBeta}
                    url={globalState.page.url}
                    channel={globalState.page.channel}
                    group={globalState.page.group}
                    price={globalState.selector.price}
                    currency={globalState.selector.currency}
                    priceYearly={globalState.selector.priceYearly}
                    yearlySelected={globalState.selector.yearlySelected}
                    currentPrice={globalState.selector.currentPrice}
                    monthlyPrice={globalState.selector.monthlyPrice}
                    yearlyPrice={globalState.selector.yearlyPrice}
                    errors={globalState.form.errors}
                    subscribing={globalState.form.subscribing}
                    period={globalState.page.period}
                    trialPeriod={globalState.page.trialPeriod}
                    openModal={globalState.openModal}
                    displayName={globalState.page.displayName}
                    image={globalState.page.image}
                    styles={typeof styles !== 'undefined' ? styles.StripeFormElementsV2 : {}}
                    modalStyles={typeof styles !== 'undefined' ? styles : {}}
                    backgroundColor={globalState.page.backgroundColor}
                    buttonColor={globalState.page.buttonColor}
                    textColor={globalState.page.textColor}
                    status={globalState.page.status}
                    alternatePayment={globalState.selector.alternatePayment}
                    alternatePaymentAmount={globalState.selector.alternatePaymentAmount}
                    joiningPayment={globalState.selector.joiningPayment}
                    joiningPaymentAmount={globalState.selector.joiningPaymentAmount}
                    quickPaymentType={globalState.selector.quickPaymentType}
                    paymentModalOpen={globalState.selector.paymentModalOpen}
                    waiveJoiningFeeForYearlyOption={globalState.page.waiveJoiningFeeForYearlyOption}
                    couponsEnabled={globalState.coupon.couponsEnabled}
                    verifyEmailEnabled={globalState.page.verifyEmailEnabled}
                    useOauthEnabled={globalState.page.useOauthEnabled}
                    requireNameEnabled={globalState.page.requireNameEnabled}
                    requireAddressEnabled={globalState.page.requireAddressEnabled}
                    customSetting1Enabled={globalState.page.customSetting1Enabled}
                    customSetting2Enabled={globalState.page.customSetting2Enabled}
                    customSetting3Enabled={globalState.page.customSetting3Enabled}
                    couponFieldVisible={globalState.coupon.couponFieldVisible}
                    couponValid={globalState.coupon.valid} // vs couponValid
                    couponId={globalState.coupon.id}
                    couponData={globalState.coupon}
                    couponAmountOff={globalState.coupon.amount_off}
                    couponPercentOff={globalState.coupon.percent_off}
                    couponDurationInMonths={globalState.coupon.duration_in_months}
                    couponCurrency={globalState.coupon.currency}
                    couponDuration={globalState.coupon.duration}
                    couponSubmitted={globalState.coupon.couponSubmitted}
                    couponValidating={globalState.coupon.couponValidating}
                    customTOS={globalState.page.customTOS}
                    email={globalState.params.email}
                    coupon={globalState.params.coupon}
                    stripe={globalState.stripe}
                    platform={globalState.page.platform}
                    coinbaseEnabled={globalState.page.coinbaseEnabled}
                    coinbaseCharge={globalState.coinbase.charge}
                    offerName={globalState.page.offerName}
                    stripeKeySCA={globalState.page.stripeKeySCA}
                    mediaAssets={globalState.page.mediaAssets}
                    displayMediaEmbed={globalState.page.displayMediaEmbed}
                    domain={globalState.businessPage.domain}
                    isBusinessPageOffer
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BusinessPage;
