/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import DefaultTOS from './default-tos';

function TermsAndConditions({
  formErrors,
  customTOS,
}) {
  if (!customTOS) {
    return <DefaultTOS />;
  }

  return (
    <div className="flex flex-col gap-2 item-start">
      <label className="!inline-flex items-start">
        <span className="text-left text-xs text-gray-100">
          By completing signup, I agree to both the terms and conditions of
          {' '}
          <a
            href="https://www.launchpass.com/terms"
            className="text-xs text-primary no-underline hover:underline hover:underline-offset-1 visited:text-primary-300"
            target="_blank"
            rel="noreferrer"
          >
            LaunchPass
          </a>
          {' '}
          and the
          {' '}
          <a
            href={customTOS}
            className="text-xs text-primary no-underline hover:underline hover:underline-offset-1 visited:text-primary-300"
            target="_blank"
            rel="noreferrer"
          >
            community
          </a>
          .
        </span>
      </label>
      {formErrors.customTOS && (
        <div
          className="text-xs text-red-100 mt-2"
        >
          You must accept the Terms &amp; Conditions
        </div>
      )}
    </div>
  );
}

export default TermsAndConditions;
