import React from 'react';
import Feature from './Feature';

function FeaturesList({ features = [] }) {
  return (
    <>
      {features.map((feature, index) => (
        <Feature key={`${index + feature.emoji}`} feature={feature} />
      ))}
    </>
  );
}

export default FeaturesList;
