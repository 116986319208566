import React, { useCallback, useEffect, useState } from 'react';

export const usePrevNextButtons = (emblaApi) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

export function PrevButton(props) {
  const { children, className, ...restProps } = props;

  return (
    <button
      className={`embla__button embla__button--prev ${className} hover:scale-110 transition-transform duration-200`}
      type="button"
      {...restProps}
    >
      <svg
        width="27"
        height="28"
        viewBox="0 0 27 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="-0.150391"
          y="0.417969"
          width="26.7"
          height="26.7"
          rx="13.35"
          fill="white"
        />
        <rect
          x="-0.150391"
          y="0.417969"
          width="26.7"
          height="26.7"
          rx="13.35"
          stroke="#E3E7EB"
          strokeWidth="0.5"
        />
        <mask
          id="mask0_2525_41345"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="4"
          width="20"
          height="20"
        >
          <rect
            x="3.59961"
            y="4.16797"
            width="19.2"
            height="19.2"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_2525_41345)">
          <path
            d="M13.9985 18.288L9.47852 13.768L13.9985 9.24805L14.8385 10.088L11.1585 13.768L14.8385 17.448L13.9985 18.288Z"
            fill="#545C63"
          />
        </g>
      </svg>

      {children}
    </button>
  );
}

export function NextButton(props) {
  const { children, className, ...restProps } = props;

  return (
    <button
      className={`embla__button embla__button--next ${className} hover:scale-110 transition-transform duration-200`}
      type="button"
      {...restProps}
    >
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.05078"
          y="0.417969"
          width="26.7"
          height="26.7"
          rx="13.35"
          fill="white"
        />
        <rect
          x="1.05078"
          y="0.417969"
          width="26.7"
          height="26.7"
          rx="13.35"
          stroke="#E3E7EB"
          strokeWidth="0.5"
        />
        <mask
          id="mask0_2525_41347"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="4"
          width="20"
          height="20"
        >
          <rect
            x="4.80078"
            y="4.16797"
            width="19.2"
            height="19.2"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_2525_41347)">
          <path
            d="M13.1212 18.288L12.2812 17.448L15.9612 13.768L12.2812 10.088L13.1212 9.24805L17.6413 13.768L13.1212 18.288Z"
            fill="#545C63"
          />
        </g>
      </svg>

      {children}
    </button>
  );
}
