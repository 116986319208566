import { useState, useEffect } from 'react';

const useScrollPastMidpoint = (ref, containerRef = null, correction = 0) => {
  const [hasScrolledPastMidpoint, setHasScrolledPastMidpoint] = useState(false);

  useEffect(() => {
    const updateMidpoint = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const midpoint = rect.top + rect.height / 2 - correction;

        // Check if the midpoint of the observed element is above half the container's height
        setHasScrolledPastMidpoint(midpoint < 0);
      }
    };

    const handleScroll = () => updateMidpoint();

    const resizeObserver = new ResizeObserver(() => updateMidpoint());
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    // Use containerRef if provided; otherwise, default to window
    const scrollElement = containerRef?.current || window;
    scrollElement.addEventListener('scroll', handleScroll, { passive: true });
    scrollElement.addEventListener('touchstart', handleScroll, { passive: true });

    // Initial check on mount
    updateMidpoint();

    return () => {
      scrollElement.removeEventListener('scroll', handleScroll);
      scrollElement.removeEventListener('touchstart', handleScroll);
      resizeObserver.disconnect();
    };
  }, [ref, containerRef]);

  return hasScrolledPastMidpoint;
};

export default useScrollPastMidpoint;
