import * as React from "react";
const SvgLinkV2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      stroke="#1E2328"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M7.533 9.734a3.667 3.667 0 0 0 5.53.396l2.2-2.2a3.666 3.666 0 0 0-5.185-5.185L8.816 4m1.65 4.268a3.666 3.666 0 0 0-5.529-.396l-2.2 2.2a3.667 3.667 0 0 0 5.185 5.185l1.254-1.254"
    />
  </svg>
);
export default SvgLinkV2;
