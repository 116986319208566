import React from 'react';
import { Emoji } from 'emoji-picker-react';

function Feature({ feature }) {
  return (
    <div className="flex gap-2">
      <div className="rounded-xl bg-gray-500 w-[40px] h-[40px] md:w-[65px] md:h-[65px] items-center min-w-[40px] md:min-w-[65px] justify-center flex">
        {feature.emoji && (
          <Emoji lazyLoad={false} unified={feature.emoji} size="25" />
        )}
      </div>
      <div className="flex flex-col">
        <div className="text-sm md:text-md text-black-pearl font-semibold">{feature.featureName}</div>
        <div className="text-sm md:text-lg text-fiord-500">{feature.featureDescription}</div>
      </div>
    </div>
  );
}

export default Feature;
