import * as React from "react";
const SvgApple = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 17"
    {...props}
  >
    <g clipPath="url(#apple_svg__a)">
      <path
        fill="#fff"
        d="M14.754 6.198c-.077.044-1.896.985-1.896 3.07.086 2.378 2.295 3.212 2.333 3.212-.038.045-.333 1.136-1.209 2.28-.695.986-1.466 1.98-2.638 1.98-1.114 0-1.514-.657-2.8-.657-1.38 0-1.771.657-2.828.657-1.172 0-2-1.047-2.733-2.023-.953-1.278-1.762-3.284-1.79-5.21-.02-1.02.19-2.023.723-2.875.752-1.19 2.095-1.997 3.562-2.023 1.123-.035 2.123.719 2.809.719.657 0 1.886-.72 3.276-.72.6.001 2.2.17 3.19 1.59M8.192 4.405c-.2-.932.352-1.864.866-2.458.658-.719 1.696-1.207 2.59-1.207a3.33 3.33 0 0 1-.952 2.512c-.58.718-1.58 1.26-2.504 1.153"
      />
    </g>
    <defs>
      <clipPath id="apple_svg__a">
        <path fill="#fff" d="M.902.74H15.48v16H.902z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgApple;
