import React, { forwardRef } from 'react';
import InputLabel from './InputLabel';

const Input = forwardRef(({
  label,
  legend,
  placeholder,
  id,
  prefix,
  suffix,
  bottom,
  inputClassName = '',
  name,
  type = 'text',
  tooltip,
  size = 'md',
  className,
  error,
  ...rest
}, ref) => {
  const handleBlur = (event) => {
    const { value } = event.target;
    if (type === 'number' && value === '') {
      event.target.value = '0';
      // If you're using react-hook-form, you might need to update the form state here
      if (rest.onChange) {
        rest.onChange({ target: { name, value: '0' } });
      }
    }
    if (rest.onBlur) {
      rest.onBlur(event);
    }
  };

  let sizeClass = 'py-3';
  if (size === 'sm') {
    sizeClass = 'py-1.5';
  }

  return (
    <div className={`flex flex-col gap-1 ${className}`} ref={ref}>
      {label && (
        <InputLabel
          label={label}
          legend={legend}
          tooltip={tooltip}
          htmlFor={id}
        />
      )}
      <div className="
        w-full
        relative
        flex
        "
      >
        {prefix && (
          <div className="absolute pl-2 top-0 flex items-center justify-center h-full">
            {prefix}
          </div>
        )}
        {type === 'textarea' ? (
          <textarea
            name={name}
            id={id}
            rows={3}
            className={`
              block w-full
              rounded-lg
              !border-0
              ${sizeClass}
              text-fiord-500
              ring-1
              ring-inset
              ${error ? 'ring-red-100' : 'ring-water-500'}
              placeholder:text-gray-200
              placeholder:text-sm
              focus:!ring-1
              focus:!ring-inset
              focus:!ring-gray-200
              sm:text-sm
              sm:leading-6
              ${inputClassName}
            `}
            placeholder={placeholder}
            {...rest}
            onBlur={handleBlur}
          />
        ) : (
          <input
            type={type}
            name={name}
            id={id}
            className={`
              block w-full
              rounded-lg
              !border-0
              ${sizeClass}
              text-fiord-500
              ring-1
              ring-inset
              ${error ? 'ring-red-100' : 'ring-water-500'}
              placeholder:text-gray-200
              placeholder:text-sm
              focus:!ring-1
              focus:!ring-inset
              focus:!ring-gray-200
              sm:text-sm
              sm:leading-6
              ${prefix && 'pl-10'}
              ${inputClassName}
            `}
            placeholder={placeholder}
            {...rest}
            onBlur={handleBlur}
          />
        )}
        {suffix}
      </div>
      {bottom}
      {error && <div className="text-red-100 text-sm">{error.message}</div>}
    </div>
  );
})

export default Input;
