import * as React from "react";
const SvgInfo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#9BA3AB"
      d="M9.25 14.75h1.5V9h-1.5zM10 7.3q.35 0 .575-.238a.82.82 0 0 0 .225-.587.79.79 0 0 0-.8-.8.792.792 0 0 0-.8.8.82.82 0 0 0 .225.587Q9.65 7.3 10 7.3m0 12.2a9.3 9.3 0 0 1-3.712-.75 9.6 9.6 0 0 1-3.013-2.025 9.6 9.6 0 0 1-2.025-3.013A9.3 9.3 0 0 1 .5 10q0-1.975.75-3.713a9.6 9.6 0 0 1 2.025-3.012A9.6 9.6 0 0 1 6.288 1.25 9.3 9.3 0 0 1 10 .5a9.3 9.3 0 0 1 3.713.75 9.6 9.6 0 0 1 3.012 2.025 9.6 9.6 0 0 1 2.025 3.012A9.3 9.3 0 0 1 19.5 10q0 1.975-.75 3.712a9.6 9.6 0 0 1-2.025 3.013 9.6 9.6 0 0 1-3.012 2.025A9.3 9.3 0 0 1 10 19.5m0-1.5q3.35 0 5.675-2.325T18 10t-2.325-5.675T10 2 4.325 4.325 2 10t2.325 5.675T10 18"
    />
  </svg>
);
export default SvgInfo;
