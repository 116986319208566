/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import DefaultTOS from './DefaultTOS';

function CustomTOS({
  formErrors,
  textColor,
  customTOS,
  isV3,
  styles,
}) {
  if (!customTOS) {
    return <DefaultTOS isV4 textColor={textColor} isV3={isV3} styles={styles} />;
  }

  return (
    <div className="flex flex-col gap-2 item-start">
      <label className="!inline-flex items-start ml-0">
        <span className="text-left text-gray-100 font-normal">
          By completing signup, I agree to both the terms and conditions of
          {' '}
          <a
            href="https://www.launchpass.com/terms"
            className="text-small text-primary no-underline hover:underline hover:underline-offset-1 visited:text-primary-300"
            target="_blank"
            rel="noreferrer"
          >
            LaunchPass
          </a>
          {' '}
          and the
          {' '}
          <a
            href={customTOS}
            className="text-small text-primary no-underline hover:underline hover:underline-offset-1 visited:text-primary-300"
            target="_blank"
            rel="noreferrer"
          >
            community
          </a>
          .
        </span>
      </label>
      {formErrors.customTOS && !isV3 && (
        <p
          style={{
            marginTop: '10px',
            marginBottom: '0px',
            visibility: 'visible',
            color: textColor,
          }}
        >
          You must accept the Terms &amp; Conditions
        </p>
      )}
    </div>
  );
}

export default CustomTOS;
