import * as React from "react";
const SvgSupportedPayments = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 116 16"
    {...props}
  >
    <path
      fill="#6461FC"
      fillRule="evenodd"
      d="m20.778 3.111-2.188.484V1.768l2.188-.474zm4.552 1.012c-.855 0-1.404.412-1.71.699l-.113-.555H21.59v10.44l2.18-.475.009-2.534c.314.233.776.564 1.543.564 1.56 0 2.982-1.29 2.982-4.128-.009-2.596-1.447-4.01-2.973-4.01m-.522 6.17c-.515 0-.82-.188-1.03-.421L23.77 6.55c.227-.26.54-.438 1.038-.438.793 0 1.342.913 1.342 2.086 0 1.2-.54 2.095-1.342 2.095m10.365-2.069c0-2.292-1.081-4.1-3.148-4.1-2.075 0-3.33 1.808-3.33 4.082 0 2.695 1.482 4.056 3.61 4.056 1.037 0 1.821-.242 2.414-.582V9.89c-.593.304-1.273.492-2.136.492-.846 0-1.595-.305-1.691-1.361h4.263c0-.05.003-.162.007-.29.005-.176.01-.383.01-.507m-4.308-.852c0-1.011.602-1.432 1.151-1.432.532 0 1.099.42 1.099 1.432zM18.59 4.275h2.188v7.834H18.59zm-2.485 0 .14.663c.514-.967 1.534-.77 1.813-.663v2.06c-.27-.1-1.142-.224-1.657.465v5.31h-2.18V4.274zm-4.222-1.942-2.127.465-.009 7.172c0 1.325.968 2.3 2.258 2.3.715 0 1.238-.134 1.526-.295v-1.817c-.279.116-1.656.528-1.656-.797V6.182h1.656V4.275h-1.656zm-5.15 3.733c-.463 0-.742.135-.742.484 0 .381.48.549 1.076.757.971.339 2.25.785 2.255 2.44 0 1.602-1.247 2.524-3.06 2.524-.75 0-1.57-.152-2.38-.51V9.63c.731.412 1.656.716 2.38.716.488 0 .836-.134.836-.546 0-.422-.52-.615-1.149-.848-.957-.355-2.164-.803-2.164-2.295 0-1.584 1.177-2.533 2.947-2.533.724 0 1.439.116 2.162.411V6.64c-.662-.367-1.5-.573-2.162-.573"
      clipRule="evenodd"
    />
    <path
      fill="#172B85"
      fillRule="evenodd"
      d="M47.905 12.725h-2.529L43.48 5.278c-.09-.343-.281-.646-.562-.788a8 8 0 0 0-2.318-.788v-.287h4.073c.563 0 .984.43 1.055.93l.984 5.373 2.527-6.303h2.46zm5.198 0h-2.388l1.967-9.31h2.388zm5.056-6.73c.07-.502.492-.789.984-.789a4.34 4.34 0 0 1 2.318.43l.422-2.005a5.9 5.9 0 0 0-2.178-.43C57.387 3.2 55.7 4.49 55.7 6.28c0 1.361 1.195 2.076 2.039 2.507.912.43 1.263.716 1.193 1.145 0 .645-.703.931-1.404.931-.844 0-1.687-.214-2.459-.573l-.422 2.006c.844.357 1.756.501 2.6.501 2.599.07 4.214-1.218 4.214-3.151 0-2.436-3.302-2.578-3.302-3.652m11.662 6.73-1.897-9.31h-2.037c-.421 0-.843.286-.984.716l-3.511 8.594h2.458l.491-1.36h3.021l.281 1.36zm-3.583-6.803.701 3.509h-1.966z"
      clipRule="evenodd"
    />
    <path
      fill="#ED0006"
      fillRule="evenodd"
      d="M84.921 11.939a5.26 5.26 0 0 1-3.398 1.236c-2.893 0-5.238-2.317-5.238-5.175 0-2.859 2.345-5.176 5.238-5.176 1.297 0 2.484.466 3.398 1.237a5.26 5.26 0 0 1 3.398-1.237c2.893 0 5.239 2.317 5.239 5.176s-2.346 5.175-5.239 5.175a5.26 5.26 0 0 1-3.398-1.236"
      clipRule="evenodd"
    />
    <path
      fill="#F9A000"
      fillRule="evenodd"
      d="M84.92 11.94A5.14 5.14 0 0 0 86.76 8a5.14 5.14 0 0 0-1.84-3.938 5.26 5.26 0 0 1 3.398-1.237c2.893 0 5.238 2.317 5.238 5.176s-2.345 5.175-5.238 5.175a5.26 5.26 0 0 1-3.398-1.236"
      clipRule="evenodd"
    />
    <path
      fill="#FF5E00"
      fillRule="evenodd"
      d="M84.922 11.939a5.14 5.14 0 0 0 1.84-3.94c0-1.577-.713-2.99-1.84-3.938A5.14 5.14 0 0 0 83.082 8a5.14 5.14 0 0 0 1.84 3.939"
      clipRule="evenodd"
    />
    <path
      fill="#F7931A"
      d="M112.034 9.486c-.81 3.31-4.114 5.327-7.381 4.506-3.266-.82-5.257-4.169-4.447-7.478s4.114-5.327 7.381-4.506l.016.005c3.258.828 5.24 4.17 4.431 7.473"
    />
    <path
      fill="#fff"
      d="M107.502 7.102c-.191.772-1.352.376-1.729.282l.333-1.35c.372.092 1.586.27 1.39 1.068zm-.208 2.182c-.205.841-1.603.386-2.056.272l.367-1.492c.454.116 1.904.342 1.689 1.22m1.524-2.17c.119-.823-.497-1.264-1.333-1.557l.274-1.114-.676-.17-.267 1.085a22 22 0 0 0-.535-.127l.267-1.096-.668-.17-.274 1.113-.427-.096-.923-.233-.177.723s.495.116.485.123a.36.36 0 0 1 .313.386l-.313 1.268a.4.4 0 0 1 .069.023l-.071-.02-.436 1.777a.244.244 0 0 1-.307.16c0 .01-.487-.121-.487-.121L103 9.84l.87.218.476.125-.276 1.125.668.17.274-1.113q.27.075.531.139l-.27 1.117.668.167.276-1.123c1.143.219 1.998.131 2.359-.914.292-.841-.015-1.325-.614-1.642.437-.102.761-.386.855-.995z"
    />
  </svg>
);
export default SvgSupportedPayments;
