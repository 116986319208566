/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

function DefaultTOS() {
  return (
    <div className="text-left">
      <label
        className="text-xs text-gray-100"
        htmlFor="tos"
      >
        <span style={{ display: 'inline-block' }}>
          By completing signup, I agree to the
          {' '}
          <a
            href="https://www.launchpass.com/terms"
            className="text-xs text-primary no-underline hover:underline hover:underline-offset-1 visited:text-primary-300"
            target="_blank"
            rel="noreferrer"
          >
            terms & conditions
          </a>
          {' '}
          of LaunchPass.
        </span>
      </label>
    </div>
  );
}

export default DefaultTOS;
