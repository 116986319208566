/* eslint-disable no-case-declarations */
import { isNonZeroValue, getNumber, formatPrice } from '../utils'

export const selector = (state = {}, action) => {
  // console.log('state', state)
  switch (action.type) {
    case 'SET_SELECTOR': {
      return {
        ...state,
        ...action.data,
      }
    }
    case 'SELECT_YEARLY':
      // console.log('select yearly', state.period, state.alternatePaymentAmount, state.alternatePayment, state.price, state.alternatePaymentAmount)
      return {
        ...state,
        yearlySelected: true,

      }
    case 'SELECT_MONTHLY':
      // console.log('select monthly', state.period, state.alternatePaymentAmount, state.alternatePayment, state.price, state.alternatePaymentAmount)
      return {
        ...state,
        yearlySelected: false,
      }
    default:
      return state
  }
}
export const enableBeta = (op) => op || false

export const openModal = (state = {}, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      }
    case 'HIDE_MODAL':
      return {
        modalType: null,
        modalProps: {},
      }
    case 'RECEIVE_SUBSCRIPTION':
      return {
        modalType: 'success',
      }
    case 'RECEIVE_SUBSCRIPTION_SCA':
      return {

        modalType: 'success',
        user: action.results.user,
        subscription: action.results.subscription,
      }
    case 'ADDITIONAL_ERROR':
      return {
        modalType: 'error',
      }
    default:
      return state
  }
}

const initialFormState = {
  errors: [],
  subscribing: false,

}

export const form = (state = initialFormState, action) => {
  switch (action.type) {
    case 'UPDATE_FORM_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      }
    case 'CLICK_TOS':
      return {
        ...state,
        tosAccepted: action.accepted,
        errors: state.errors ? state.errors.filter(e => !e.field === 'customTOS') : [],
      }
    case 'INVALIDATE_FORM':
      return {
        ...state,
        errors: action.errors,
      }
    case 'CLEAR_ERROR':
      return {
        ...state,
        errors: state.errors.filter(error => error.field !== action.field),
      }
    case 'CLEAR_NON_FIELD_ERRORS':
      return {
        ...state,
        errors: state.errors.filter(error => typeof error.field !== 'undefined'),
      }
    case 'CLEAR_ERRORS':
      return {
        ...state,
        errors: [],
      }
    case 'ADDITIONAL_ERROR':
      return {
        ...state,
        subscribing: false,
        errors: [action.error],
      }
    case 'REQUEST_SUBSCRIPTION':
      return {
        ...state,
        subscribing: true,
      }
    case 'RECEIVE_SUBSCRIPTION':
      return {
        ...state,
        subscribing: false,
        errors: [],
      }
    case 'RECEIVE_SUBSCRIPTION_SCA':
      return {
        ...state,
        subscribing: false,
        errors: [],
        user: action.user,
        subscription: action.subscription,
      }
    case 'SET_COINBASE_CHARGE':
      return {
        ...state,
        subscribing: false,
        errors: [],
      }
    default:
      return state
  }
}

export const page = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_PAGE':
      return {
        ...state,
        ...action.page,
      }
    case 'SET_PAGE':
      return {
        ...action.page,
      }
    case 'SELECT_YEARLY':
      return {
        ...state,
        //period: 'yearly',
        //price: state.price * 10,
      }
    case 'SELECT_MONTHLY':
      return {
        ...state,
        //period: 'monthly',
        //price: state.price / 10,
      }
    default:
      return state
  }
}

export const stripe = (state = {}, action) => {
  switch (action.type) {
    case 'SET_STRIPE': {
      return action.stripe
    }
    default:
      return state
  }
}

export const coinbase = (state = {}, action) => {
  switch (action.type) {
    case 'SET_COINBASE_CHARGE': {
      return {
        ...state,
        charge: action.value
      }
    }
    default:
      return state
  }
}

export const coupon = (state = {}, action) => {
  switch (action.type) {
    case 'TOGGLE_COUPON_FIELD':
      return {
        ...state,
        couponFieldVisible: action.visible,
      }
    case 'COUPON_VALID':
      return {
        ...state,
        couponSubmitted: true,
        ...action.coupon,
      }
    case 'COUPON_INVALID':
      return {
        ...state,
        couponSubmitted: true,
      }
    case 'COUPON_VALIDATING':
      return {
        ...state,
        couponValidating: action.status,
      }
    case 'COUPON_RESET':
      return {
        ...state,
        valid: false,
        couponSubmitted: false,
        couponValidating: false,
      }
    case 'UPDATE_COUPON_CONFIG': {
      return {
        ...state,
        ...action.coupon,
        couponFieldVisible: false,
        valid: false,
        couponSubmitted: false,
        couponValidating: false,
      };
    }
    default:
      return state
  }
}

export const params = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export const businessPage = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export const selectedOffer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_OFFER': {
      return {
        ...state,
        ...action.offer,
      }
    }
    default:
      return state
  }
}
