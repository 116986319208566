import * as React from "react";
const SvgCoinbase = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      fill="#0052FF"
      d="M2.268 12.585c0-5.574 4.45-10.13 10.03-10.102 5.94.027 10.179 4.901 10.106 10.231-.073 5.362-4.295 9.922-10.115 9.963-5.59.04-10.022-4.486-10.021-10.092"
    />
    <path
      fill="#fff"
      d="M9.193 6.367c1.835-.996 4.13-1.075 6.037-.24 2.035.87 3.56 2.755 4.06 4.902-1.101.003-2.205.04-3.305-.02-.653-1.828-2.911-2.797-4.718-2.164-1.6.497-2.804 2.128-2.679 3.821-.02 1.99 1.773 3.76 3.753 3.755 1.585.09 3.109-.95 3.707-2.398a169 169 0 0 1 3.242.002c-.395 1.714-1.415 3.308-2.898 4.276-1.661 1.177-3.856 1.493-5.807.965-1.845-.514-3.471-1.806-4.333-3.524-.9-1.72-1.033-3.82-.335-5.634a6.98 6.98 0 0 1 3.276-3.741"
    />
  </svg>
);
export default SvgCoinbase;
