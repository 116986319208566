import * as React from "react";
const SvgGithubSecondary = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="#545C63"
      fillRule="evenodd"
      d="M9 .875a8.16 8.16 0 0 0-2.58 15.904c.409.074.557-.178.557-.394 0-.194-.007-.707-.01-1.388-2.27.493-2.75-1.094-2.75-1.094-.37-.942-.905-1.193-.905-1.193-.741-.507.056-.497.056-.497.819.059 1.25.842 1.25.842.727 1.246 1.91.886 2.374.678.075-.528.285-.887.518-1.091-1.811-.206-3.717-.906-3.717-4.033 0-.891.319-1.62.84-2.19-.084-.206-.364-1.036.08-2.16 0 0 .685-.219 2.244.837A7.8 7.8 0 0 1 9 4.821a7.8 7.8 0 0 1 2.043.275c1.558-1.056 2.242-.837 2.242-.837.445 1.124.165 1.954.081 2.16.523.57.84 1.299.84 2.19 0 3.134-1.909 3.824-3.727 4.026.293.252.554.75.554 1.512 0 1.09-.01 1.97-.01 2.238 0 .218.147.472.561.392A8.16 8.16 0 0 0 9 .876"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGithubSecondary;
