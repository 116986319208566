import * as React from "react";
const SvgTiktokSecondary = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#tiktok-secondary_svg__a)">
      <path
        fill="#747D85"
        d="M18.56 8.404c-1.756.039-3.395-.507-4.8-1.522v6.985c0 2.654-1.64 4.995-4.137 5.932a6.29 6.29 0 0 1-6.985-1.756 6.39 6.39 0 0 1-.82-7.22c1.25-2.302 3.825-3.59 6.478-3.238v3.512a2.95 2.95 0 0 0-3.278 1.053c-.702 1.054-.702 2.42.04 3.434.74 1.015 2.068 1.444 3.238 1.054a2.93 2.93 0 0 0 2.03-2.77V.208h3.434c0 .312 0 .585.078.898a4.62 4.62 0 0 0 2.107 3.121c.742.508 1.678.78 2.615.78z"
      />
    </g>
    <defs>
      <clipPath id="tiktok-secondary_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTiktokSecondary;
