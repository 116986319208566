import React from 'react'
import classNames from 'classnames'

import ProfileIcons from './ProfileIcons'
import { getJoinButtonText } from '../utils'

const AboutHeader = ({
  styles,
  profileIconsStyles,
  channel,
  group,
  image,
  displayName,
  offerName,
  color,
  period,
  isNotV3 = false,
  trialPeriod,
  theme,
  textColor,
  isV4,
}) => {
  let aboutStyles
  let aboutTitle
  let userName

  aboutTitle = styles.aboutTitle
  userName = styles.userName

  const titleColor = !textColor ? (theme === 'light' ? '!text-fiord-500' : '!text-white') : ''
  const subtitleColor = !textColor ? (theme === 'light' ? 'text-black-pearl' : '!text-white') : ''

  if (isV4) {
    return (
      <div className={styles.container}>
        <div className={`${styles.upperContainer} flex items-center !gap-2.5`}>
          {image && (
            <ProfileIcons
              styles={profileIconsStyles}
              channel={channel}
              group={group}
              image={image}
              isV4={isV4}
            />
          )}
          {(!channel || channel === 'p/slack') && (
            <div className={classNames(aboutTitle, aboutStyles)}>
              <h1 className={`!text-sm ${titleColor} !font-semibold !mb-0`} style={{ color: textColor || 'inherit' }}>Join {displayName}</h1>
              <p className={`${userName} !text-2xl ${subtitleColor} !font-semibold`} style={{ color: textColor || 'inherit' }}>{group}</p>
            </div>
          )}
          {channel && channel !== 'p/slack' && (
            <div className={classNames(aboutTitle, aboutStyles)}>
              <h1 className={`!text-sm ${titleColor} !font-semibold !mb-0`} style={{ color: textColor || 'inherit' }}>Join {displayName}</h1>
              <p className={`${userName} !text-2xl ${subtitleColor} !font-semibold`} style={{ color: textColor || 'inherit' }}>{offerName}</p>
            </div>
          )}
        </div>
  
        {/* Backward Compatibility: V1/2 Paid page only, V3 Paid page and Free page (now V3 Free) and not rendering this component */}
        {period !== 'free' && isNotV3 && (
          <button
            className={styles.joinBtn}
            style={{ background: color || '#4D4C69', width: 190 }}
          >
            {getJoinButtonText(trialPeriod)}
          </button>
        )}
        {/* Backward Compatibility: Copied back current Legacy Join Button scroll animation stuff, only for V1/2 Paid pages */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
          $(document).ready(function() {
              $('.' + styles.AboutHeader.joinBtn).on('click', function() {
                  console.log("should animate")
                  $('html, body').animate({
                      scrollTop: $("#signup-section").offset().top
                  }, 1500);
              });
              $('.' + styles.AboutHeader.mediaJoinBtn).on('click', function() {
                  console.log("should animate")
                  $('html, body').animate({
                      scrollTop: $("#signup-section").offset().bottom
                  }, 1500);
              });
            })
        `,
          }}
        />
      </div>
    )  
  }

  return (
    <div className={styles.container}>
      <div className={styles.upperContainer}>
        {image && (
          <ProfileIcons
            styles={profileIconsStyles}
            channel={channel}
            group={group}
            image={image}
          />
        )}
        {(!channel || channel === 'p/slack') && (
          <div className={classNames(aboutTitle, aboutStyles)}>
            <h1>{displayName}</h1>
            <p className={userName}>{group}</p>
          </div>
        )}
        {channel && channel !== 'p/slack' && (
          <div className={classNames(aboutTitle, aboutStyles)}>
            <h1>{displayName}</h1>
            <p className={userName}>{offerName}</p>
          </div>
        )}
      </div>

      {/* Backward Compatibility: V1/2 Paid page only, V3 Paid page and Free page (now V3 Free) and not rendering this component */}
      {period !== 'free' && isNotV3 && (
        <button
          className={styles.joinBtn}
          style={{ background: color || '#4D4C69', width: 190 }}
        >
          {getJoinButtonText(trialPeriod)}
        </button>
      )}
      {/* Backward Compatibility: Copied back current Legacy Join Button scroll animation stuff, only for V1/2 Paid pages */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
          document.addEventListener('DOMContentLoaded', function() {
            function scrollToSignup() {
                console.log("should animate");
                const element = document.getElementById("signup-section")

                if (element) {
                  element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'start',
                  });
                }
            }
        
            var joinBtns = document.querySelectorAll('.' + styles.AboutHeader.joinBtn);
            var mediaJoinBtns = document.querySelectorAll('.' + styles.AboutHeader.mediaJoinBtn);
        
            joinBtns.forEach(function(btn) {
                btn.addEventListener('click', scrollToSignup);
            });
        
            mediaJoinBtns.forEach(function(btn) {
                btn.addEventListener('click', scrollToSignup);
            });
        });
        
      `,
        }}
      />
    </div>
  )
}

export default AboutHeader
