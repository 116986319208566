import * as React from "react";
const SvgThreadsSecondary = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="#545C63"
      d="M9.08 18c-1.82 0-2.657-.224-4.047-.973-1.205-.648-2.243-1.516-2.8-2.762C.244 9.803.848 3.128 5.104.91 7.266-.216 10.694-.34 12.89.777c2.106 1.07 3.125 2.741 3.968 4.885l-1.72.492c-.795-2.887-2.71-4.487-5.818-4.487-1.228 0-2.343.123-3.28.588-2.24 1.11-3.158 3.714-3.248 6.352-.066 1.917.244 3.852 1.1 5.244.978 1.592 3.01 2.6 4.917 2.6 1.785 0 3.221-.31 4.33-1.35 1.508-1.415 1.822-3.672-.1-5.049-.593 5.056-7.449 4.768-7.449.75q0-1.252.974-2.185c1.288-1.235 3.227-.87 4.844-.894-.17-.763-.82-1.87-2.064-1.87-.96 0-1.787.242-2.232.985l-1.34-.869C6.34 4.904 7.485 4.204 9.24 4.204s3.473 1.149 3.758 3.327l.088.672c.992.4 1.706 1.147 2.202 1.9 1.394 2.115.626 4.829-1.365 6.516q-1.629 1.38-4.842 1.38zm-1.89-7.233c0 .828.878 1.447 1.728 1.447q1.423 0 1.957-.802.533-.803.533-1.933c-.974-.248-2.67-.425-3.568.17-.423.28-.65.636-.65 1.118"
    />
  </svg>
);
export default SvgThreadsSecondary;
