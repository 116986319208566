import * as React from "react";
const SvgTwitterSecondary = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#747D85"
      d="M15.75.834h3.067l-6.7 7.658 7.882 10.42h-6.172l-4.833-6.32-5.531 6.32H.394l7.166-8.19L0 .833h6.328l4.37 5.777zm-1.076 16.243h1.7L5.403 2.573H3.58z"
    />
  </svg>
);
export default SvgTwitterSecondary;
