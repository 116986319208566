import React from 'react'

import { hideModal } from '../client/subscribe/actions'
import { CloseIcon } from '../icons'

import Button from './v2/Button'

const handleModal = (modalType) => (event) => {
  event.preventDefault()
  store.dispatch(hideModal(modalType))
  const inputs = document.getElementsByTagName('input')
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].value = ''
  }
  return false
}

function WelcomeModal({ channel, group, platform }) {
  return (
    <div className="fixed inset-0 z-[100] bg-[rgba(80,80,80,0.58)]" onClick={handleModal('close')}>
      <div className="absolute z-[110] w-full h-auto m-0 sm:w-[60%] sm:top-[10%] sm:ml-[20%] sm:mt-[10%] sm:text-center sm:rounded-[7px] flex flex-col gap-6 p-10 bg-white">
        <span className="hover:cursor-pointer top-4 right-4 absolute" onClick={handleModal('close')}>
          <CloseIcon width={32} height={32} />
        </span>

        <div id="welcomeHeader" className="text-black-pearl font-semibold text-2xl md:text-4xl">
          {channel && channel !== 'p/slack'
            ? `Welcome to #${channel}!`
            : `Welcome to the ${group} group!`}
        </div>

        <div className="text-fiord-500 text-sm md:text-base">
          We're so excited you're here! Check your email for your
          {' '}
          {platform === 'telegram' ? 'Telegram' : 'Slack'}
          {' '}
          invitation and directions to get set up. Can't wait to talk to
          you soon! If you have any questions, please contact LaunchPass support at support@LaunchPass.com.
        </div>
        <div className="flex justify-center">
          <Button
            onClick={(e) => {
              handleModal('close')(e)
            }}
          >
            Got it!
          </Button>
        </div>
      </div>
    </div>
  );
}

export default WelcomeModal
