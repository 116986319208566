import React from 'react';
import { SOCIALS } from '../constants';

export default function Socials({ socials = {}, isPreview }) {
  const finalSocials = [];
  Object.keys(socials ?? {}).forEach((key) => {
    if (SOCIALS[key] && socials[key]) {
      finalSocials.push({
        Icon: SOCIALS[key].Icon,
        label: SOCIALS[key].label,
        value: socials[key],
      });
    }
  });

  if (!finalSocials || !finalSocials.length) {
    return null;
  }

  return (
    <>
      <div className="bg-gray-400 h-[1px] w-full my-4" />
      <div className="text-black-pearl text-md font-semibold mb-4">
        Links
      </div>
      <div className="grid grid-cols-2 gap-2">
        {finalSocials.map((item) => {
          const { Icon, label, value } = item;
          const formattedValue = value.startsWith('http://') || value.startsWith('https://') ? value : `https://${value}`;
          return (
            <a
              href={!isPreview ? formattedValue : null}
              target="_blank"
              className="flex gap-2 items-center no-underline text-gray-100 hover:underline hover:cursor-pointer"
              key={item.label}
              rel="noreferrer"
              {...(isPreview ? {
                onClick: (e) => e.preventDefault(),
              } : {})}
            >
              <div className="bg-white border items-center p-2 justify-center flex border-gray-400 w-[40px] h-[40px] rounded-lg">
                <Icon className="w-full min-w-[19.25px] max-w-[19.25px]" />
              </div>
              <div className="text-sm text-gray-100 font-semibold">
                {label}
              </div>
            </a>
          );
        })}
      </div>
    </>
  );
}
