import React from 'react';
import lodashCapitalize from 'lodash.capitalize';
import {
  DiscordIcon, SlackIcon, TelegramIcon, VerifiedIcon,
} from '../../../icons';

const PLATFORM_ICONS = {
  discord: DiscordIcon,
  slack: SlackIcon,
  telegram: TelegramIcon,
};

function HeaderSection({
  businessName, tagline, logo, platform, businessCategory, small,
}) {
  const PlatformIcon = PLATFORM_ICONS[platform] || (() => null);
  return (
    <div className={`${small ? 'py-2 flex-row flex items-center' : 'pt-6 pb-4 flex sm:flex-row flex-col'} gap-2 sm:gap-3 w-full justify-between`}>
      <div className="flex sm:gap-4 gap-2 flex-1 items-center">
        <div className={`relative rounded-lg ${small ? 'w-[25px] min-w-[25px] h-[25px] sm:w-[45px] sm:min-w-[45px] sm:h-[45px]' : 'sm:w-[67px] sm:min-w-[67px] sm:h-[67px] w-[45px] min-w-[45px] h-[45px]'}  border border-gray-400`}>
          <img src={logo?.url ?? '/static/landerb/images/preview.svg'} alt={businessName} className="rounded-lg w-full h-full object-cover" />
          {/* {!small && (
            <div className="block sm:hidden absolute top-[-10px] right-[-10px]">
              <VerifiedIcon style={{ minWidth: '20px' }} width="20px" height="20px" />
            </div>
          )} */}
        </div>
        <div className={`flex flex-col ${small ? '' : 'gap-1 flex-1'}`}>
          <div className={`flex gap-2 ${small ? 'items-center' : 'items-baseline'}`}>
            <div className={`${small ? 'sm:text-md' : 'sm:text-2xl'} flex items-center text-lg font-semibold text-black-pearl`}>
              {businessName}
            </div>
            {/* <span className={`${small ? 'block' : 'hidden sm:block'}`}>
              <VerifiedIcon style={{ minWidth: '20px' }} width="20px" height="20px" />
            </span> */}
          </div>
          <div className={`text-sm md:text-md text-black-pearl ${small ? 'sm:block hidden' : 'sm:block hidden'}`}>{tagline}</div>
        </div>
      </div>
      <div className={`text-sm text-black-pearl ${small ? 'hidden' : 'sm:hidden block'}`}>{tagline}</div>
      <div className="flex gap-2 items-center md:pt-2 h-fit">
        {/* <div className="text-xs flex gap-1 rounded-full py-1 px-2 text-primary-500 items-center bg-primary-500/10">
          <PlatformIcon width={14} height={14} />
          {!small && lodashCapitalize(platform)}
        </div> */}
        <div className="text-xs text-gray-100 bg-gray-100/10 py-1 px-2 rounded-full">{businessCategory.label}</div>
      </div>
    </div>
  )
}

export default HeaderSection;
