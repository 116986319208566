import React from 'react';
import PropTypes from 'prop-types';

const sizeClasses = {
  sm: 'text-sm px-3 py-1.5',
  md: 'text-md px-5 py-2.5',
  lg: 'text-lg px-6 py-3',
};

const variantClasses = {
  default: 'text-white bg-primary hover:bg-blue-800 focus:ring-primary-300',
  alternative: 'text-fiord-500 bg-white border border-water-500 hover:bg-light-gray-200 focus:ring-gray-100',
  alternativeDark: 'text-black-pearl bg-white border border-water-500 hover:bg-light-gray-200 focus:ring-gray-100',
  light: 'text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 focus:ring-gray-100',
  green: 'text-white bg-green-700 hover:bg-green-800 focus:ring-green-300',
  red: 'text-white bg-red-700 hover:bg-red-800 focus:ring-red-300',
  yellow: 'text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-yellow-300',
  purple: 'text-white bg-purple-700 hover:bg-purple-800 focus:ring-purple-300',
  link: 'text-black-pearl bg-transparent hover:bg-white',
};

function Component({ children, isLink, ...props }) {
  return isLink ? <a {...props}>{children}</a> : <button {...props}>{children}</button>
}
function Button({
  size, variant, icon, children, className, disabled, isLink, ...props
}) {
  const baseClasses = 'hover:cursor-pointer font-normal rounded-lg focus:outline-none no-underline flex items-center text-center justify-center gap-2'; // Add flex and align center
  const sizeClass = sizeClasses[size];
  const variantClass = variantClasses[variant];
  const disabledClass = disabled ? 'opacity-70 cursor-not-allowed' : '';

  return (
    <Component
      className={`${baseClasses} ${sizeClass} ${variantClass} ${disabledClass} ${className}`}
      disabled={disabled}
      isLink={isLink}
      {...props}
    >
      {icon}
      {children}
    </Component>
  );
}

Button.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  variant: PropTypes.oneOf([
    'default',
    'alternative',
    'light',
    'green',
    'red',
    'yellow',
    'purple',
    'link',
  ]),
  icon: PropTypes.node, // New prop for icon
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  size: 'md',
  variant: 'default',
  icon: null, // Default icon as null
};

export default Button;
