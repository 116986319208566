import * as React from "react";
const SvgYoutubeSecondary = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#747D85"
      d="M19.56 4.94C20 6.474 20 9.765 20 9.765s0 3.254-.44 4.826a2.44 2.44 0 0 1-1.754 1.755c-1.573.402-7.788.402-7.788.402s-6.252 0-7.825-.402a2.44 2.44 0 0 1-1.755-1.755C0 13.02 0 9.766 0 9.766s0-3.291.438-4.827a2.49 2.49 0 0 1 1.755-1.791c1.573-.439 7.825-.439 7.825-.439s6.215 0 7.788.439c.84.22 1.535.914 1.755 1.791M7.97 12.726l5.192-2.961L7.97 6.804z"
    />
  </svg>
);
export default SvgYoutubeSecondary;
