import React, { useState, useEffect } from 'react';
import useDragScroll from '../useDragScroll';
import OfferCard from './OfferCard'
import ScrollButton from './ScrollButton';

function OfferCardList({ offers, selectedOfferId, onSelect }) {
  const [isLastItemVisible, setIsLastItemVisible] = useState(true);

  const {
    containerRef,
    isDragging,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleMouseLeave,
  } = useDragScroll();

  useEffect(() => {
    if (selectedOfferId && containerRef.current) {
      const selectedCard = containerRef.current.querySelector(`[data-asset-id="${selectedOfferId}"]`);
      if (selectedCard) {
        selectedCard.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  }, [selectedOfferId]);

  // Check if the last item is fully visible
  const checkLastItemVisibility = () => {
    if (containerRef.current && offers.length > 0) {
      const container = containerRef.current;
      const lastItem = container.lastElementChild;

      if (lastItem) {
        const containerRight = container.getBoundingClientRect().right;
        const lastItemRight = lastItem.getBoundingClientRect().right;

        setIsLastItemVisible(lastItemRight <= containerRight);
      }
    }
  };

  useEffect(() => {
    checkLastItemVisibility();
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', checkLastItemVisibility, { passive: true });
      container.addEventListener('touchstart', checkLastItemVisibility, { passive: true });
      window.addEventListener('resize', checkLastItemVisibility);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', checkLastItemVisibility);
        container.removeEventListener('touchstart', checkLastItemVisibility);
        window.removeEventListener('resize', checkLastItemVisibility);
      }
    };
  }, [offers]);

  const scrollContainer = (direction) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className="relative">
      <div className="flex justify-between items-center mb-4">
        <div className="text-base md:text-xl font-semibold text-black-pearl">
          Choose an offer
        </div>

        <div className="flex gap-1 md:hidden">
          {offers.map((offer) => (
            <div
              key={offer.id}
              className={`w-1.5 h-1.5 rounded-full bg-gray-300 ${selectedOfferId === offer.id ? 'bg-gray-800' : ''}`}
            />
          ))}
        </div>
        {offers?.length > 2 && (
          <div className="gap-2 hidden md:flex">
            <ScrollButton direction="left" onClick={() => scrollContainer('left')} />
            <ScrollButton direction="right" onClick={() => scrollContainer('right')} />
          </div>
        )}
      </div>

      <div className="relative">
        {/* Shadow overlay - right side only, shown when last item is not fully visible */}
        {!isLastItemVisible && (
          <div className="absolute inset-y-0 right-0 w-16 bg-gradient-to-l from-white via-white/70 to-transparent pointer-events-none z-10" />
        )}

        <div
          ref={containerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          className="flex overflow-x-auto gap-2 sm:gap-4 scroll-smooth snap-x snap-mandatory hide-scrollbar cursor-grab active:cursor-grabbing"
          style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
        >
          {offers.map((offer) => (
            <OfferCard
              className="sm:max-w-[282px] gap-1 sm:gap-2 sm:min-w-[282px] max-w-[200px] min-w-[200px] snap-start cursor-pointer"
              key={offer.id}
              offer={offer}
              selected={selectedOfferId === offer.id}
              onSelect={() => !isDragging && onSelect(offer.id)}
            />

          ))}
        </div>
      </div>
    </div>
  );
}

export default OfferCardList;
