import * as React from "react";
const SvgInstagramSecondary = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#instagram-secondary_svg__a)">
      <path
        fill="#747D85"
        d="M10 0C7.284 0 6.944.012 5.877.06 4.812.11 4.086.278 3.45.525a4.9 4.9 0 0 0-1.772 1.153A4.9 4.9 0 0 0 .524 3.45C.276 4.085.107 4.812.06 5.877.012 6.943 0 7.284 0 10s.012 3.056.06 4.122c.05 1.065.218 1.792.465 2.428a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.77 1.154c.637.247 1.364.416 2.428.465 1.067.048 1.407.06 4.123.06s3.056-.012 4.122-.06c1.065-.049 1.793-.218 2.429-.465a4.9 4.9 0 0 0 1.77-1.154 4.9 4.9 0 0 0 1.154-1.771c.246-.636.415-1.363.465-2.427.048-1.067.06-1.407.06-4.123s-.012-3.057-.06-4.123c-.05-1.065-.219-1.792-.465-2.427a4.9 4.9 0 0 0-1.154-1.772A4.9 4.9 0 0 0 16.55.525C15.911.278 15.184.109 14.12.06 13.053.012 12.714 0 9.997 0zm-.897 1.802H10c2.67 0 2.987.01 4.041.058.975.044 1.504.207 1.857.344.466.181.8.398 1.149.748.35.35.567.683.748 1.15.137.352.3.881.345 1.856.048 1.054.058 1.371.058 4.04s-.01 2.985-.058 4.04c-.045.974-.208 1.504-.345 1.856a3.1 3.1 0 0 1-.748 1.149c-.35.35-.682.566-1.15.747-.351.138-.88.3-1.856.345-1.054.048-1.37.058-4.04.058s-2.987-.01-4.042-.058c-.975-.045-1.504-.208-1.857-.345a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.748-1.15c-.137-.351-.3-.88-.344-1.855-.048-1.055-.058-1.371-.058-4.042s.01-2.985.058-4.04c.044-.974.207-1.503.344-1.856.181-.467.398-.8.748-1.15s.684-.567 1.15-.748c.353-.138.882-.3 1.857-.345.923-.042 1.28-.054 3.144-.056zm6.235 1.66a1.2 1.2 0 1 0 0 2.401 1.2 1.2 0 0 0 0-2.4M10 4.866a5.136 5.136 0 1 0 0 10.271 5.136 5.136 0 0 0 0-10.271m0 1.802a3.333 3.333 0 1 1 0 6.666 3.333 3.333 0 0 1 0-6.666"
      />
    </g>
    <defs>
      <clipPath id="instagram-secondary_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgInstagramSecondary;
