import React from 'react';
import { Tooltip } from 'react-tooltip';
import { InfoIcon } from '../../icons';

function InputLabel({
  id, label, legend, tooltip, name, className,
}) {
  return (
    <div className="flex flex-row gap-2 items-center">
      <label
        htmlFor={id}
        className={`block text-sm font-medium leading-6 text-fiord-500 ${className}`}
      >
        {label}
        {' '}
        <i className="font-light text-gray-100">{legend}</i>
      </label>
      {tooltip && (
        <>
          <div
            data-tooltip-id={`${name}-tooltip`}
            className="w-5 cursor-pointer"
          >
            <InfoIcon />
          </div>
          <Tooltip id={`${name}-tooltip`}>
            <span>{tooltip}</span>
          </Tooltip>
        </>
      )}
    </div>
  )
};

export default InputLabel
