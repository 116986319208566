import React from 'react';
import {
  TwitterSecondaryIcon,
  FacebookSecondaryIcon,
  YoutubeSecondaryIcon,
  InstagramSecondaryIcon,
  TiktokSecondaryIcon,
  ThreadsSecondaryIcon,
  TwitchSecondaryIcon,
  GithubSecondaryIcon,
  LinkV2Icon,
} from '../../icons';

export const SOCIALS = {
  twitterUrl: {
    Icon: TwitterSecondaryIcon,
    label: 'X (Twitter)',
  },
  facebookUrl: {
    Icon: FacebookSecondaryIcon,
    label: 'Facebook',
  },
  youtubeUrl: {
    Icon: YoutubeSecondaryIcon,
    label: 'Youtube',
  },
  instagramUrl: {
    Icon: InstagramSecondaryIcon,
    label: 'Instagram',
  },
  tiktokUrl: {
    Icon: TiktokSecondaryIcon,
    label: 'TikTok',
  },
  threadsUrl: {
    Icon: ThreadsSecondaryIcon,
    label: 'Threads',
  },
  twitchUrl: {
    Icon: TwitchSecondaryIcon,
    label: 'Twitch',
  },
  githubUrl: {
    Icon: GithubSecondaryIcon,
    label: 'Github',
  },
  customProfileUrl: {
    Icon: LinkV2Icon,
    label: 'Spotlight Link'
  }
};
