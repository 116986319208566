import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

function LightboxGallery({
  assets,
  isOpen,
  initialIndex = 0,
  onClose,
  onSlideChange,
  onPrevButtonClick,
  onNextButtonClick,
  videoHandlers,
}) {
  const [currentSlide, setCurrentSlide] = useState(initialIndex);

  useEffect(() => {
    setCurrentSlide(initialIndex);
  }, [initialIndex]);

  useEffect(() => {
    if (onSlideChange) {
      onSlideChange(currentSlide);
    }
  }, [currentSlide, onSlideChange]);

  // Add keyboard event handler
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isOpen) return;

      if (e.key === 'ArrowLeft') {
        plusSlides(e, -1);
      } else if (e.key === 'ArrowRight') {
        plusSlides(e, 1);
      } else if (e.key === 'Escape') {
        handleCloseModal();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen]); // Only re-run if isOpen changes

  const handleCloseModal = () => {
    onClose && onClose();
  };

  const plusSlides = (e, n) => {
    setCurrentSlide((prev) => (prev + n + assets.length) % assets.length);
    if (n === -1) {
      onPrevButtonClick(e);
    } else {
      onNextButtonClick(e);
    }
  };

  return (
    <div>
      {isOpen && (
        <div
          className="w-full fixed inset-0 bg-black bg-opacity-95 flex items-center justify-center"
          style={{ zIndex: 999999 }}
        >
          <button
            className="absolute top-5 right-5 text-white text-3xl cursor-pointer border-none bg-transparent"
            style={{ zIndex: 99999 }}
            onClick={handleCloseModal}
            aria-label="Close gallery"
          >
            &times;
          </button>
          <div className="relative w-full bg-transparent p-4">
            {assets.map((asset, index) => (
              <div
                key={asset.public_id}
                className={`${
                  index === currentSlide ? 'block px-10' : 'hidden'
                } mySlides`}
              >
                <div className="text-gray-700 text-sm absolute top-0 left-0 p-2">
                  {index + 1}
                  {' '}
                  /
                  {assets.length}
                </div>
                {asset.type === 'image' ? (
                  <img src={asset.url} className="mx-auto" alt={asset.alt} />
                ) : asset.type === 'custom-video' ? (
                  <div className="flex justify-center items-center h-full">
                    <ReactPlayer
                      onStart={() => videoHandlers.handlePlay(index)}
                      onPlay={() => videoHandlers.handlePlay(index)}
                      onPause={videoHandlers.handleStop}
                      style={{
                        width: '100%',
                        height: '100%',
                        maxHeight: '600px',
                        maxWidth: '1100px',
                      }}
                      url={asset.url}
                      controls
                      playing={videoHandlers.playing[index] || false}
                    />
                  </div>
                ) : (
                  <video controls className="mx-auto">
                    <source src={asset.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}

            {/* Next/previous controls */}
            <button
              className="absolute top-1/2 left-0 transform -translate-y-1/2 text-white text-2xl cursor-pointer p-4 border-none bg-transparent"
              onClick={(e) => plusSlides(e, -1)}
              aria-label="Previous image"
            >
              &#10094;
            </button>
            <button
              className="absolute top-1/2 right-0 transform -translate-y-1/2 text-white text-2xl cursor-pointer p-4 border-none bg-transparent"
              onClick={(e) => plusSlides(e, 1)}
              aria-label="Next image"
            >
              &#10095;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LightboxGallery;
