import React, { useState, useEffect } from 'react';
import { ExpressCheckoutElement } from '@stripe/react-stripe-js';
import Spinner from '../clientAdmin/components/Spinner'
import CircleStarsIcon from './Icons/circle-stars'
import { setSelector } from '../client/subscribe/actions'

import { CoinbaseIcon } from '../icons'

function SignupSectionV2({
  disabled,
  subscribing,
  buttonColor,
  trialPeriod,
  styles,
  subtotal,
  isHalfVisible,
  onSubmit,
  isEmbed,
  quickPaymentType,
  paymentModalOpen,
  isBusinessPageOffer,
}) {
  const [showOnlyQuickPayment, setShowQuickPayment] = useState(false);
  const scrollToBottom = (e) => {
    e.preventDefault();
    // Using setTimeout to ensure this runs after any pending DOM updates
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
      });
    }, 0); // minimal timeout
  };

  const btnDisabled = isHalfVisible && disabled;

  const onQuickPayClick = ({ resolve }) => {
    const options = {
      emailRequired: true,
    };
    resolve(options);
  };

  useEffect(() => {
    if (!paymentModalOpen) {
      setShowQuickPayment(false);
    }
  }, [paymentModalOpen])

  const onQuickPayConfirm = async (event) => {
    return onSubmit(event)
  };

  return (
    <div className={`
      flex
      inset-x-0 bg-white shadow-custom
      shadow-md border-t border-t-gray-400 rounded-t-2xl
      flex-col bottom-0 sm:w-auto sm:relative w-full left-0 bg-white
      overflow-hidden z-10 gap-2

      ${!isEmbed ? 'sm:shadow-none sm:border-none sm:p-0 sm:rounded-none fixed sm:flex fixed' : 'flex sm:p-0 shadow-none border-none rounded-none rounded-t-none'}
    `}
    style={{ minHeight: "44px" }}
    >
      {!isEmbed && trialPeriod > 0 && isHalfVisible && (
        <div className="flex sm:hidden items-center justify-between bg-blue-200 py-[8px] px-[24px]">
          <div className="text-blacl-pearl font-semibold text-base gap-1 trial-period">
            {`Free for ${trialPeriod} day${trialPeriod > 1 ? 's' : ''}`}
          </div>
          <CircleStarsIcon />
        </div>
      )}

      {(quickPaymentType && quickPaymentType !== 'crypto') && isBusinessPageOffer && (
        <>
          {!showOnlyQuickPayment && (
            <div class="animate-pulse">
              <div class="h-4 bg-gray-200 rounded" style={{ height: "44px" }}></div>
            </div>
          )}
          {paymentModalOpen && (
            <ExpressCheckoutElement
              onClick={onQuickPayClick}
              onConfirm={onQuickPayConfirm}
              options={{
                layout: {
                  maxColumns: 1,
                  maxRows: 10,
                  auto: 'tabs',
                },
                paymentMethodOrder: ['google_pay', 'apple_pay'],
              }}
              onReady={(event) => {
                setShowQuickPayment(true);
                store.dispatch(setSelector({
                  expressCheckoutOptions: event,
                }))
              }}
            />
          )}
        </>
      )}

      <div className={`flex flex-col bg-white gap-2 sm:gap-0 w-full ${!isEmbed ? 'sm:p-0 p-4' : 'p-0'}`}>
        {!isEmbed && isHalfVisible && (
          <div className="sm:hidden flex justify-between gap-2 items-center">
            <div className="text-black-pearl font-semibold flex items-center font-sm">Total due today</div>
            <div className="text-black-pearl font-semibold flex items-center font-sm">{subtotal}</div>
          </div>
        )}

        {(!quickPaymentType || quickPaymentType === 'crypto') && (
          <button
            {...((isEmbed || isHalfVisible)
              ? { type: 'submit' }
              : { onClick: scrollToBottom }
              )
            }
            type="submit"
            form="payment-form"
            disabled={btnDisabled}
            {...(quickPaymentType !== 'crypto' ? {
              style: { backgroundColor: buttonColor ?? 'initial' },
            } : {})}
            className={`
              ${styles.TierModal.payButton} flex gap-1 items-center justify-center ${quickPaymentType === 'crypto' ? 'bg-primary-100 hover:bg-blue-800 hover:cursor-pointer' : 'bg-primary'} sm:w-full ${
              btnDisabled ? 'opacity-30' : ''
            }
            `}
          >
            {quickPaymentType === 'crypto' ? (
              <>
                {subscribing && <Spinner color="#FFFFFF" size={25} />}
                {!subscribing && <CoinbaseIcon width="25px" height="25px" />}
                {!subscribing && !isHalfVisible ? 'Coinbase Pay' : ''}
                {!subscribing && isHalfVisible && (trialPeriod > 0 ? 'Start Trial' : 'Coinbase Pay')}
              </>
            ) : (
              <>
                {subscribing && <Spinner color="#FFFFFF" size={25} />}
                {!subscribing && !isHalfVisible ? 'Join now' : ''}
                {!subscribing && isHalfVisible && (trialPeriod > 0 ? 'Start Trial' : 'Join now')}
              </>
            )}

          </button>
        )}
      </div>
    </div>
  );
}

export default SignupSectionV2;
