import * as React from "react";
const SvgClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <mask
      id="close_svg__a"
      width={32}
      height={32}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 0h32v32H0z" />
    </mask>
    <g mask="url(#close_svg__a)">
      <path
        fill="#747D85"
        d="m10.491 24-1.158-1.158 6.176-6.176-6.176-6.175 1.158-1.158 6.176 6.175 6.175-6.175L24 10.491l-6.175 6.175L24 22.842 22.842 24l-6.175-6.176z"
      />
    </g>
  </svg>
);
export default SvgClose;
